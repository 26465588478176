import Link from "next/link";

import { Image } from "@/components";

import styles from "./Announcements.module.scss";
import { useImageLinks } from "./helpers";
import { ImageLinkList } from "../ImageLinkList";

export function Announcements(): React.ReactNode {
  const { imageLinks, outletLink } = useImageLinks();

  return (
    <div>
      {outletLink && <ImageLinkList images={imageLinks} />}

      <div className="pd__bottom__s">
        <Link href="/products/basebread/chocolate" className={styles.link}>
          <Image
            src="https://asset.basefood.co.jp/images/bnr/bnr_renew_4sku_W1080_H1080.jpg?0701"
            alt="BASE BREAD リニューアル"
            containerClassName={styles.imageContainer}
            className={styles.image}
          />
        </Link>
      </div>
    </div>
  );
}
