import { useMemo } from "react";

import clsx from "clsx";

import { DeliveryDate } from "@/components/template/mypage/MyPageIndex/helpers";
import { OrderWithDeliveryDateModel } from "@/models/orderWithDeliveryDate/type";
import { SubscriptionResponseModel } from "@/models/subscription/type";

import { DeliveryAddition } from "./DeliveryAddition";
import styles from "./DeliverySchedule.module.scss";
import { DeliveryScheduleDetail } from "./DeliveryScheduleDetail";

interface DeliveryScheduleProps {
  subscription: SubscriptionResponseModel;
  normalDeliveryDate?: DeliveryDate;
  freezeDeliveryDate?: DeliveryDate;
  orderWithDeliveryDate?: OrderWithDeliveryDateModel;
  freezeOrderWithDeliveryDate?: OrderWithDeliveryDateModel;
}

export function DeliverySchedule({
  subscription,
  normalDeliveryDate,
  freezeDeliveryDate,
  orderWithDeliveryDate,
  freezeOrderWithDeliveryDate,
}: DeliveryScheduleProps): React.ReactNode {
  const showComebackSubscription =
    !subscription.subscription &&
    !subscription.freezeSubscription &&
    subscription.comebackSubscriptionProducts.length > 0;
  const showComebackFreezeSubscription =
    !subscription.subscription &&
    !subscription.freezeSubscription &&
    subscription.comebackFreezeSubscriptionProducts.length > 0;

  // 定期契約の表示順を決定
  const [normalOrder, freezeOrder] = useMemo(() => {
    if (
      normalDeliveryDate &&
      freezeDeliveryDate &&
      normalDeliveryDate.deliveryDate > freezeDeliveryDate.deliveryDate
    ) {
      return [2, 1];
    }

    if (!normalDeliveryDate && freezeDeliveryDate) {
      return [2, 1];
    }

    return [1, 2];
  }, [normalDeliveryDate, freezeDeliveryDate]);

  return (
    <section className={clsx("bg__yellow", styles.section, styles.deliverySchedule)}>
      <h3 className={"text__l text__bold mg__bottom__m"}>
        配送予定の
        <br className={styles.titleNewLine} />
        継続コース
      </h3>
      <div className={styles.subscription}>
        {subscription.subscription ? (
          <div className={styles.subscriptionRelative} style={{ order: normalOrder }}>
            {subscription.subscription && normalDeliveryDate && (
              <DeliveryScheduleDetail
                temperature={"normal"}
                subscription={subscription.subscription}
                deliveryDate={normalDeliveryDate}
                orderWithDeliveryDate={orderWithDeliveryDate}
              />
            )}
          </div>
        ) : (
          <div style={{ order: normalOrder }}>
            <DeliveryAddition
              temperature={"normal"}
              showComeback={showComebackSubscription}
              products={subscription.comebackSubscriptionProducts}
              isFreezeDeliveryArea={subscription.freezeDeliveryArea}
            />
          </div>
        )}
        {subscription.freezeSubscription ? (
          <div className={styles.subscriptionRelative} style={{ order: freezeOrder }}>
            {subscription.freezeSubscription && freezeDeliveryDate && (
              <DeliveryScheduleDetail
                temperature={"freeze"}
                subscription={subscription.freezeSubscription}
                deliveryDate={freezeDeliveryDate}
                orderWithDeliveryDate={freezeOrderWithDeliveryDate}
              />
            )}
          </div>
        ) : (
          <div style={{ order: normalOrder }}>
            <DeliveryAddition
              temperature={"freeze"}
              showComeback={showComebackFreezeSubscription}
              products={subscription.comebackSubscriptionProducts}
              isFreezeDeliveryArea={subscription.freezeDeliveryArea}
            />
          </div>
        )}
      </div>
    </section>
  );
}
