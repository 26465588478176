import { useMemo } from "react";

import clsx from "clsx";
import Link from "next/link";

import { Image } from "@/components";
import { OrderWithDeliveryDateDeliveryStatus } from "@/generated/open-api/schemas";
import { orderDeliveryStatusLabels } from "@/models/orderWithDeliveryDate/consts";
import { OrderWithDeliveryDateModel } from "@/models/orderWithDeliveryDate/type";
import { ProductTemperature } from "@/models/product/type";
import { SubscriptionModel } from "@/models/subscription/type";
import { getWeekDay } from "@/utils";

import styles from "./DeliveryScheduleDetail.module.scss";
import {
  DeliveryDate,
  extractMonthAndDateString,
  extractMonthAndDateStringWithWeekDay,
  isLastOrderDelivered,
} from "../../helpers";

interface DeliveryScheduleDetailProps {
  temperature: ProductTemperature;
  subscription: SubscriptionModel;
  deliveryDate: DeliveryDate;
  orderWithDeliveryDate?: OrderWithDeliveryDateModel;
}

const DELIVERY_STATUS_COLOR = {
  [OrderWithDeliveryDateDeliveryStatus.Shipped]: "text__green",
  [OrderWithDeliveryDateDeliveryStatus.PartlyShipped]: "text__red",
  [OrderWithDeliveryDateDeliveryStatus.Cancel]: "text__red",
  [OrderWithDeliveryDateDeliveryStatus.None]: "text__yellow",
};

export function DeliveryScheduleDetail({
  temperature,
  subscription,
  deliveryDate,
  orderWithDeliveryDate,
}: DeliveryScheduleDetailProps): React.ReactNode {
  const showLatestAndNextOrder = useMemo(
    () =>
      subscription.orderIds.length > 0 &&
      !isLastOrderDelivered(subscription, orderWithDeliveryDate),
    [orderWithDeliveryDate, subscription]
  );

  const lastOrderId = useMemo(() => {
    const orders = subscription.orders;
    return orders[orders.length - 1]?.id;
  }, [subscription]);

  const deliveryStatusLabel = useMemo(() => {
    if (!orderWithDeliveryDate?.deliveryStatus) return orderDeliveryStatusLabels["None"];
    return orderDeliveryStatusLabels[orderWithDeliveryDate.deliveryStatus];
  }, [orderWithDeliveryDate]);

  const deadlineDate = useMemo(() => {
    return extractMonthAndDateStringWithWeekDay(subscription.nextOrderChangeDeadlineDate);
  }, [subscription]);

  const lastDeliveryDate = useMemo(() => {
    return extractMonthAndDateStringWithWeekDay(deliveryDate.dateWithWeekDay);
  }, [deliveryDate]);

  const nextOrderArrivalDate = useMemo(() => {
    return `${extractMonthAndDateString(subscription.nextOrderArrivalDate)}(${getWeekDay(subscription.nextOrderArrivalDate)})`;
  }, [subscription]);

  const statusColor = useMemo(() => {
    return DELIVERY_STATUS_COLOR[
      orderWithDeliveryDate?.deliveryStatus ?? OrderWithDeliveryDateDeliveryStatus.None
    ];
  }, [orderWithDeliveryDate?.deliveryStatus]);

  const products = useMemo(() => {
    return subscription.products.map((product) => {
      return {
        image: product.images[0],
        quantity: product.quantity,
        variantTitle: product.variantTitle,
      };
    });
  }, [subscription.products]);

  return (
    <div id="delivery_schedule_component" className={styles.subscriptionDate}>
      {deliveryDate.deliveryDate && (
        <div className={clsx("bg__white mg__bottom__s", styles.subscriptionLink)}>
          {temperature === "normal" ? (
            <span
              className={clsx("tag inline yellow text__white mini square", styles.subscriptionTag)}
            >
              常温配送
            </span>
          ) : (
            <span
              className={clsx(
                "tag inline darkblue text__white mini square",
                styles.subscriptionTag
              )}
            >
              冷凍配送
            </span>
          )}

          {showLatestAndNextOrder ? (
            <span className={clsx("text__m text__bold", statusColor)}>{deliveryStatusLabel}</span>
          ) : (
            <span className="text__m text__bold text__success">変更{deadlineDate}まで</span>
          )}

          {showLatestAndNextOrder && (
            <div className="pd__bottom__m">
              <p className="text__m mg__top__xs mg__bottom__s">
                <span className="text__bold">
                  {lastDeliveryDate}
                  {deliveryDate.timezoneSuffix}
                </span>
                {/*スペースを入れている*/}
                {` ${deliveryDate.suffix}`}
              </p>

              <Link
                className={clsx("text__s", styles.orderDetailLink)}
                href={`/mypage/order_detail?order_id=${lastOrderId}`}
              >
                ご注文の詳細を見る
              </Link>
            </div>
          )}

          <div className={showLatestAndNextOrder ? clsx("bg__gray", styles.revocableOrder) : ""}>
            <p className="text__m mg__top__xs">
              <span className="text__bold">
                {nextOrderArrivalDate}
                {subscription.calcNextOrderArrivalTimezoneStr}
              </span>
              {/*スペースを入れている*/}
              {` ${deliveryDate.suffix}`}
            </p>

            <div className={clsx("mg__top__s mg__bottom__m", styles.productsContainer)}>
              <ul className={styles.products}>
                {products.map((product) => (
                  <li className={styles.product} key={product.variantTitle}>
                    <Image
                      src={product.image}
                      alt={product.variantTitle}
                      size={{ width: 55, height: 55 }}
                    />
                    <span
                      className={clsx(
                        "text__s text__bold text__white text__center",
                        styles.productCount
                      )}
                    >
                      {product.quantity}
                    </span>
                  </li>
                ))}
              </ul>
            </div>

            <div className="text__center">
              <Link
                href={
                  temperature === "normal" ? "/mypage/subscription" : "/mypage/freeze_subscription"
                }
                className={clsx(
                  "btn inline round angle__right wsnr",
                  showLatestAndNextOrder ? "bg__white" : "bg__gray"
                )}
              >
                次回お届け日・内容を変更する<i className="fas fa-angle-right"></i>
              </Link>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
