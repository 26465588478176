import { RankNameTransformed } from "@/generated/open-api/schemas";

export const RANK_MAP: Record<RankNameTransformed, { rankImage: string; rankNameJp: string }> = {
  Bronze: {
    rankImage: "https://asset.basefood.co.jp/images/parts/rank_bronz.svg",
    rankNameJp: "ブロンズ",
  },
  Silver: {
    rankImage: "https://asset.basefood.co.jp/images/parts/rank_silver.svg",
    rankNameJp: "シルバー",
  },
  Gold: {
    rankImage: "https://asset.basefood.co.jp/images/parts/rank_gold.svg",
    rankNameJp: "ゴールド",
  },
  Diamond: {
    rankImage: "https://asset.basefood.co.jp/images/parts/rank_diamond.svg",
    rankNameJp: "ダイヤモンド",
  },
} as const;
