import { useMemo } from "react";

import clsx from "clsx";
import Link from "next/link";

import { paymentMethodLabels } from "@/models/payment/consts";
import { useParsedGetCustomer, useParsedGetSubscription } from "@/queries";
import { usePrefetchCallbacks } from "@/utils/hooks";
import { getDomesticProvince } from "@/utils/province";

import styles from "./CustomerBox.module.scss";

interface CustomerBoxProps {}

export function CustomerBox({}: CustomerBoxProps): React.ReactNode {
  const { data: customer } = useParsedGetCustomer();
  const { data: subscription } = useParsedGetSubscription();
  const { prefetchOrdersCallback } = usePrefetchCallbacks();
  const province = useMemo(
    () =>
      customer?.address?.province ? getDomesticProvince(customer.address.province)?.jp ?? "" : "",
    [customer]
  );

  const paymentMethod =
    subscription?.subscription?.paymentMethod ?? subscription?.freezeSubscription?.paymentMethod;
  const paymentMethodLabel = paymentMethod ? paymentMethodLabels[paymentMethod] : "";

  return (
    <div className={clsx("bg__white mg__bottom__m", styles.section, styles.customer)}>
      <div className={clsx("mg__bottom__m", styles.flex)}>
        <h1 className="text__l text__bold">お客様情報</h1>
        <Link href="/mypage/address" className={clsx("btn inline gray mini", styles.flexRight)}>
          各種変更
        </Link>
      </div>
      <dl className={clsx("text__m", styles.row)}>
        <dt className={clsx("text__bold", styles.column)}>お名前</dt>
        <dd className={styles.columnLast}>
          {customer.lastName} {customer.firstName} さま
        </dd>
      </dl>
      <dl className={clsx("text__m mg__top__s", styles.row)}>
        <dt className={clsx("text__bold", styles.column)}>ご住所</dt>
        {customer.address && (
          <dd className={styles.columnLast}>
            {customer.address.zip}
            <br />
            {province}
            {customer.address.city}
            {customer.address.addressLine1}
            {customer.address.addressLine2}
          </dd>
        )}
      </dl>
      <dl className={clsx("text__m mg__top__s", styles.row)}>
        <dt className={clsx("text__bold", styles.column)}>電話番号</dt>
        {customer.address && customer.address.phone && (
          <dd className={styles.columnLast}>{customer.address.phone}</dd>
        )}
      </dl>
      <dl className={clsx("text__m mg__top__s", styles.row)}>
        <dt className={clsx("text__bold", styles.column)}>メール</dt>
        <dd className={clsx("wordbreak", styles.columnLast)}>{customer.email}</dd>
      </dl>
      {(subscription.subscription || subscription.freezeSubscription) && (
        <dl className={clsx("text__m mg__top__s", styles.row)}>
          <dt className={clsx("text__bold", styles.column)}>決済方法</dt>
          <dd className={styles.columnLast}>{paymentMethodLabel}</dd>
        </dl>
      )}
      <Link
        href="/mypage/purchase_history"
        className={clsx("text__m text__bold", styles.historyLink)}
        onMouseEnter={prefetchOrdersCallback({ page: 1 })}
        onFocus={prefetchOrdersCallback({ page: 1 })}
      >
        注文・ポイント履歴
        <i className={clsx("fas fa-angle-right", styles.angle)}></i>
      </Link>
    </div>
  );
}
