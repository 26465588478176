"use client";
import { useMemo } from "react";

import clsx from "clsx";

import { Row } from "@/components/containers";
import { Button } from "@/components/inputs";
import { PRODUCT_SALE_PARAMETER } from "@/configs/system";
import { CartModel } from "@/models/cart/type";
import { numberFormat } from "@/utils";
import { sumBy } from "@/utils/array";
import { formatCurrency } from "@/utils/currency";

import styles from "./BottomActions.module.scss";

interface BottomActionsProps {
  cart: CartModel;
  validPurchase: boolean;
  canChangeOrder: boolean;
  couponError?: string;
  submitButtonTitle: string;
  showButtons?: boolean;
  canSubmit: boolean;
  onSubmit: () => void;
  onCancel: () => void;
}

export function BottomActions({
  cart,
  validPurchase,
  canChangeOrder,
  couponError,
  submitButtonTitle,
  showButtons,
  canSubmit,
  onSubmit,
  onCancel,
}: BottomActionsProps): React.ReactNode {
  const totalAmount = useMemo(() => sumBy(cart.products, "quantity"), [cart.products]);

  return (
    <Row className={styles.root}>
      <div className={clsx("container", styles.body)}>
        <div className={styles.leftContainer}>
          {!canChangeOrder && <span>1回目お届け以降、お届け日・内容変更が可能になります。</span>}
          {!validPurchase && (
            <span>
              商品金額{numberFormat(PRODUCT_SALE_PARAMETER.minDeliveryAmountNormal)}
              円以上からご購入いただけます。
            </span>
          )}
          {couponError && (
            <span>
              適用中のクーポンの条件外となります。お届け予定日・注文内容の変更、もしくはクーポンの削除をお願いいたします。
            </span>
          )}
        </div>
        <div className={styles.rightContainer}>
          <div className={styles.totalPriceContainer}>
            <Row className={styles.totalPriceItemContainer}>
              <span className="text__m">袋数：</span>
              <span className="text__l text__bold">{totalAmount}</span>
            </Row>
            <Row className={styles.totalPriceItemContainer}>
              <span className="text__m">合計金額：</span>
              <span className="text__l text__bold">
                {cart.totalPriceWithNoPointDiscount !==
                  cart.totalPrice + cart.totalPriceCashDeliveryFee && (
                  <del className={clsx("text__s", "text__normal", styles.deletedPrice)}>
                    {formatCurrency(cart.totalPriceWithNoPointDiscount)}
                  </del>
                )}
                {formatCurrency(cart.totalPrice + cart.totalPriceCashDeliveryFee)}
              </span>
            </Row>
          </div>
          {showButtons && (
            <Row className={styles.buttonsContainer}>
              <Button variants="gray" rounded onClick={onCancel}>
                キャンセル
              </Button>
              <Button variants="yellow" rounded disabled={!canSubmit} onClick={onSubmit}>
                {submitButtonTitle}
              </Button>
            </Row>
          )}
        </div>
      </div>
    </Row>
  );
}
