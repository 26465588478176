"use client";

import { useState } from "react";

import clsx from "clsx";
import { format } from "date-fns";
import Link from "next/link";

import Pagination from "@/components/displays/Pagination";
import { useParsedGetCustomerPoints } from "@/queries";
import { usePrefetchCallbacks } from "@/utils/hooks";

import { PointHistoryDataEmptyBody } from "./PointHistoryDataEmptyBody";
import styles from "./PointHistoryTable.module.scss";
import { HistoryDataLoadingBody } from "../HistoryDataLoadingBody";

export function PointHistoryTable(): React.ReactNode {
  const [page, setPage] = useState(1);
  const { isLoading, data } = useParsedGetCustomerPoints(page);
  const { prefetchPointDetailCallback } = usePrefetchCallbacks();

  const paginationPart = <Pagination onPageChange={setPage} totalPage={data?.meta.lastPage || 0} />;

  return (
    <>
      {/* PC版 */}
      <div className="order__histories__pc">
        <table className="text__center">
          <thead>
            <tr className="text__s">
              <th>反映日</th>
              <th>種類</th>
              <th>ポイント数量</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {isLoading && <HistoryDataLoadingBody colSpan={5} />}
            {!isLoading && !data?.points.length && <PointHistoryDataEmptyBody colSpan={5} />}

            {data?.points.map((point) => (
              <tr key={point.id}>
                <td>
                  <p>{format(point.createdAt, "yyyy/MM/dd")}</p>
                </td>
                <td>
                  <p>{point.label}</p>
                </td>
                <td>
                  <p className={clsx("text__bold", point.point >= 0 ? "text__green" : "text__red")}>
                    <span>{`${point.point >= 0 ? "+" : "-"}${Math.abs(point.point)}`}</span>
                  </p>
                </td>
                <td>
                  <p>
                    {point.name === "PurchasePoint" && (
                      <Link
                        href={`/mypage/purchase_history/point_detail/${point.id}`}
                        className={styles.link}
                        onMouseEnter={prefetchPointDetailCallback({ point_id: point.id })}
                        onFocus={prefetchPointDetailCallback({ point_id: point.id })}
                      >
                        購入の詳細を見る
                      </Link>
                    )}
                  </p>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {paginationPart}
      </div>
      {/* end of PC版 */}

      {/* SP版 */}
      <div className="order__histories">
        {!isLoading && !data?.points.length && <PointHistoryDataEmptyBody inTable={false} />}
        {data?.points.map((point) => (
          <div key={point.id} className="order__history pd__top__m">
            <div className="mg__bottom__m order__detail">
              <dl>
                <dt>
                  <p>反映日：</p>
                </dt>
                <dd>
                  <p className="text__right">{format(point.createdAt, "yyyy/MM/dd")}</p>
                </dd>
              </dl>
              <dl>
                <dt>
                  <p className="wsnr">種類：</p>
                </dt>
                <dd>
                  <p className="text__right text__bold">
                    <span>{point.label}</span>
                  </p>
                </dd>
              </dl>
              <dl>
                <dt>
                  <p>ポイント数量：</p>
                </dt>
                <dd>
                  <p
                    className={clsx(
                      "text__right text__bold",
                      point.point >= 0 ? "text__green" : "text__red"
                    )}
                  >
                    <span>{`${point.point >= 0 ? "+" : "-"}${Math.abs(point.point)}`}</span>
                  </p>
                </dd>
              </dl>
              <dl>
                <dt />
                <dd>
                  <p className="text__right">
                    {point.name === "PurchasePoipointnt" && (
                      <Link
                        href={`/mypage/purchase_history/point_detail/${point.id}`}
                        className={styles.link}
                      >
                        購入の詳細を見る
                      </Link>
                    )}
                  </p>
                </dd>
              </dl>
            </div>
          </div>
        ))}
        {paginationPart}
      </div>
      {/* end of SP版 */}
    </>
  );
}
