"use client";
import { useEffect, useMemo } from "react";

import clsx from "clsx";
import { addDays, format, parse } from "date-fns";
import { ja } from "date-fns/locale";
import { deepEqual } from "fast-equals";
import {
  Controller as RHFController,
  ControllerProps,
  FormProvider,
  useForm,
} from "react-hook-form";
import { Tooltip } from "react-tooltip";

import { Column, Row } from "@/components/containers";
import { Button, Checkbox, DatePicker, Selector } from "@/components/inputs";
import {
  DeliveryReceiveOption,
  deliveryReceiveOptionLabels,
  deliveryTimeZoneLabels,
} from "@/models/delivery/consts";
import { PaymentMethod } from "@/models/payment/consts";
import { SubscriptionModel } from "@/models/subscription/type";
import { useParsedGetValidDates } from "@/queries/order/useParsedGetValidDates";
import { nl2br } from "@/utils/jsx";
import { objectEntries } from "@/utils/object";
import { useOnChange } from "@/utils/zod";

import styles from "./DeliveryScheduleForm.module.scss";
import { DeliveryScheduleFormSchemaValue } from "./schema";

const deliveryTimeOptions = objectEntries(deliveryTimeZoneLabels).map(([value, label]) => ({
  value,
  label,
}));

const receiveOptions = objectEntries(deliveryReceiveOptionLabels).map(([value, label]) => ({
  value,
  label,
}));

interface DeliveryScheduleFormProps {
  subscription: SubscriptionModel | undefined;
  values?: Partial<DeliveryScheduleFormSchemaValue>;
  onChange: (values: Partial<DeliveryScheduleFormSchemaValue>) => void;
  onSkip: (() => void) | undefined;
  paymentMethod: PaymentMethod | undefined;
  canEdit?: boolean;
}

export function DeliveryScheduleForm({
  subscription,
  values,
  onChange,
  onSkip,
  paymentMethod,
  canEdit,
}: DeliveryScheduleFormProps): React.ReactNode {
  const { data } = useParsedGetValidDates();
  const [minDate, maxDate] = useMemo(() => {
    if (!data.validDates.length) return [undefined, undefined];
    const last = data.validDates.length - 1;
    const minDate = parse(data.validDates[0].value, "yyyy/MM/dd", new Date());
    const maxDate = parse(data.validDates[last].value, "yyyy/MM/dd", new Date());
    return [minDate, maxDate] as const;
  }, [data.validDates]);

  const formContext = useForm<DeliveryScheduleFormSchemaValue>({
    defaultValues: values,
  });

  useEffect(() => {
    const { getValues, reset } = formContext;
    const innerValues = getValues();
    if (!deepEqual(innerValues, values)) {
      reset(values);
    }
  }, [formContext, values]);

  useOnChange<DeliveryScheduleFormSchemaValue>(onChange, formContext);

  const [arrivalTimeFrom, arrivalTimeTo] = useMemo(() => {
    if (!subscription) {
      return ["", ""] as const;
    }
    const toDate = parse(subscription.calcNextOrderArrivalDate, "yyyy/MM/dd", new Date());
    const fromDate = addDays(toDate, -3);
    return [
      format(fromDate, "yyyy/MM/dd(E)", { locale: ja }),
      format(toDate, "yyyy/MM/dd(E)", { locale: ja }),
    ] as const;
  }, [subscription]);

  return (
    <FormProvider {...formContext}>
      <Column className={styles.fieldSection}>
        <Row className={styles.fieldLabel}>
          <p>次回のご注文</p>
          <span className={clsx("text__s", "text__gray__dark")}>
            {subscription?.nextOrderFixDate} に注文確定
          </span>
        </Row>
        <Column>
          <Controller
            name="deliveryDate"
            render={({ field }) => (
              <DatePicker
                disabled={!canEdit}
                className={styles.datePicker}
                min={minDate}
                max={maxDate}
                {...field}
              />
            )}
          />
          <Row>
            <Controller
              name="deliveryTimezone"
              render={({ field: { ref, ...field } }) => (
                <Selector
                  className={styles.timeZoneSelector}
                  innerRef={ref}
                  disabled={!canEdit}
                  options={deliveryTimeOptions}
                  {...field}
                />
              )}
            />
            {onSkip && (
              <Button
                variants="gray"
                className={styles.skipButton}
                disabled={!canEdit}
                onClick={onSkip}
              >
                スキップ
              </Button>
            )}
          </Row>
        </Column>
      </Column>
      <Column className={styles.fieldSection}>
        <Row className={styles.fieldLabel}>
          <p>
            受取方法：
            {paymentMethod === PaymentMethod.daibiki &&
              deliveryReceiveOptionLabels[DeliveryReceiveOption.FaceToFace]}
          </p>
        </Row>
        {paymentMethod !== PaymentMethod.daibiki && (
          <Controller
            name="deliveryReceiveOption"
            render={({ field: { ref, ...field } }) => (
              <Selector innerRef={ref} disabled={!canEdit} options={receiveOptions} {...field} />
            )}
          />
        )}
      </Column>
      <Column className={styles.fieldSection}>
        <Controller
          name="isFastDelivery"
          render={({ field: { value, ...field } }) => (
            <Checkbox checked={value} disabled={!canEdit} {...field}>
              出荷から最短でお届け
              <i
                id="estimate-arrival-time"
                className={clsx("fas", "fa-question-circle", styles.toolTipIcon)}
              />
              <Tooltip anchorSelect="#estimate-arrival-time">
                {nl2br(
                  `商品の出荷後、お届け予定日に関わらず最短でお届けする設定です。

                   次回お届け日の目安：
                   ${arrivalTimeFrom} ~ ${arrivalTimeTo}

                   ※配送業者での営業所管理をせずにお届けします。
                   ※次回のご注文以降も同様の設定になります。`
                )}
              </Tooltip>
            </Checkbox>
          )}
        />
      </Column>
    </FormProvider>
  );
}

function Controller<Name extends keyof DeliveryScheduleFormSchemaValue>(
  props: ControllerProps<DeliveryScheduleFormSchemaValue, Name>
) {
  return <RHFController {...props} />;
}
