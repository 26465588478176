import { useCallback, useState } from "react";

import clsx from "clsx";
import { Tooltip } from "react-tooltip";

import { MyPageModal } from "@/components/template/mypage/MyPageModal";
import { RankNameTransformed } from "@/generated/open-api/schemas";

import styles from "./NutrientTooltipIcon.module.scss";

import "@/style/rankColor.scss";

export interface NutrientTooltipIconProps {
  rankName: RankNameTransformed;
}

export function NutrientTooltipIcon({ rankName }: NutrientTooltipIconProps): React.ReactNode {
  const [isTooltipModalOpen, setIsTooltipModalOpen] = useState(false);

  const toggleTooltipModal = useCallback(() => {
    setIsTooltipModalOpen((prev) => !prev);
  }, []);

  return (
    <>
      <div className="clear__sp">
        <i
          className={clsx("fas fa-question-circle text__m", `${rankName}InnerFont`)}
          id="question"
        ></i>
        <Tooltip anchorSelect="#question" className={styles.tooltip}>
          {`累計食数を「BASE BREAD チョコレート」
1袋分の各栄養成分値を元に算出し、食材例
で換算したものです。各食材の栄養成分は
八訂食品栄養成分表を参照し、各食材重量は
「食品の栄養とカロリー事典 第3版 |
女子栄養大学出版部 」を参照しています。`}
        </Tooltip>
      </div>
      <div className="clear__pc">
        <i
          className={clsx("fas fa-question-circle text__m", `${rankName}InnerFont`)}
          id="question"
          onClick={toggleTooltipModal}
        ></i>
        <MyPageModal
          open={isTooltipModalOpen}
          closeModal={toggleTooltipModal}
          footer={
            <button className="btn inline gray square text__m" onClick={toggleTooltipModal}>
              閉じる
            </button>
          }
        >
          <p className="text__l text__bold pd__top__m">栄養素・食材の数値について</p>
          <p className="text__m mg__top__m">
            累計食数を「BASE BREAD
            <sup className="sup">
              <i className="far fa-registered fa-fw" />
            </sup>
            チョコレート」1袋分の各栄養成分値を元に算出し、食材例で換算したものです。各食材の栄養成分は八訂食品栄養成分表を参照し、各食材重量は「食品の栄養とカロリー事典
            第3版 | 女子栄養大学出版部 」を参照しています。
          </p>
        </MyPageModal>
      </div>
    </>
  );
}
