"use client";
import { useCallback } from "react";

import clsx from "clsx";
import { format } from "date-fns";

import { RankName } from "@/generated/open-api/schemas";
import { RoyaltyRank } from "@/models/rank/type";
import { getParsedGetMilesGiftUrl } from "@/queries";

import styles from "./RankUpGift.module.scss";

interface RankUpGiftProps {
  royaltyRanks: RoyaltyRank[];
}

const rankUpColor: Record<RankName, string> = {
  BRONZE: "", // BRONZEが来ることはないが、型エラーになるため。今後追加される可能性も考え空文字を指定
  SILVER: "bg__darkgray",
  GOLD: "bg__yellow",
  DIAMOND: "bg__darkblue",
};

export function RankUpGift({ royaltyRanks }: RankUpGiftProps): React.ReactNode {
  const openRankUpUrl = useCallback(async (id: number) => {
    const { data } = await getParsedGetMilesGiftUrl(id.toString());
    window.open(data.url, "_blank");
  }, []);

  return (
    <>
      {royaltyRanks.map((royaltyRank) => (
        <div
          className={clsx("bg__darkgray", styles.container, rankUpColor[royaltyRank.rankName])}
          key={royaltyRank.id}
        >
          <dl className={styles.flex}>
            <dt>
              <p
                className={clsx(
                  "text__s text__bold",
                  royaltyRank.rankName === "DIAMOND" && "text__white"
                )}
              >
                ランクアップしました！
                <br />
                おめでとうございます！
              </p>
            </dt>
            <dd className="text__right text__s">
              {royaltyRank.urlExpirationDate && (
                <span className={clsx(royaltyRank.rankName === "DIAMOND" && "text__white")}>
                  有効期限：{format(royaltyRank.urlExpirationDate, "yyyy/MM/dd")}
                </span>
              )}
              <br />
              {royaltyRank.url ? (
                <a
                  href={royaltyRank.url}
                  target="_blank"
                  className={clsx("btn inline gray mini wsnr", styles.rankUpButton)}
                >
                  プレゼントを受けとる
                </a>
              ) : (
                <button
                  className={clsx("btn inline gray mini wsnr", styles.rankUpButton)}
                  onClick={() => openRankUpUrl(royaltyRank.id)}
                >
                  プレゼントを受けとる
                </button>
              )}
            </dd>
          </dl>
        </div>
      ))}
    </>
  );
}
