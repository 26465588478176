"use client";

import { useCallback, useState } from "react";

import clsx from "clsx";

import { Modal, ModalProps } from "@/components/feedbacks";
import { Button } from "@/components/inputs";
import { useDeleteSubscription } from "@/generated/open-api/subscription/subscription";
import { useSaveSurvey } from "@/generated/open-api/survey/survey";

import styles from "./AfterUnsubscribeSurveyModal.module.scss";
import {
  SurveyAnswers,
  SurveyForm,
  SurveyFormProps,
  covertToApiSurveyAnswers,
} from "../SurveyForm";

export interface AfterUnsubscribeSurveyModalProps {
  subscriptionId: string | number;
  email: string;
  questions: SurveyFormProps["questions"];
  modalProps: ModalProps;
}

export function AfterUnsubscribeSurveyModal({
  subscriptionId,
  email,
  questions,
  modalProps,
}: AfterUnsubscribeSurveyModalProps): React.ReactNode {
  const [answers, setAnswers] = useState<SurveyAnswers>({});
  const { mutateAsync: saveSurvey } = useSaveSurvey();
  const { mutateAsync: deleteSubscription } = useDeleteSubscription();

  const handleUnsubscribe = useCallback(
    (sendSurvey: boolean) => async () => {
      if (sendSurvey) {
        // アンケート結果を送信する
        await saveSurvey({
          data: {
            answers: covertToApiSurveyAnswers(answers),
            email,
          },
        });
      }

      // 解約処理
      await deleteSubscription({ params: { subscription_id: subscriptionId.toString() } });

      modalProps.closeModal();
    },
    [answers, deleteSubscription, email, modalProps, saveSurvey, subscriptionId]
  );

  return (
    <Modal {...modalProps} displayCloseButton={false} className={styles.modalContainer}>
      <SurveyForm onChange={setAnswers} questions={questions} />
      <div className={clsx("border__top pd__top__m text__right", styles.footerContainer)}>
        <Button className={styles.answerBtn} onClick={handleUnsubscribe(true)} variants="yellow">
          回答する
        </Button>
        <Button onClick={handleUnsubscribe(false)} variants="gray">
          回答しない
        </Button>
      </div>
    </Modal>
  );
}
