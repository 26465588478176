"use client";

import { useCallback } from "react";

import clsx from "clsx";

import { Image } from "@/components/displays";
import { useGetMyPageSummaryImageName } from "@/generated/open-api/customer/customer";

import styles from "./SnsShareModal.module.scss";
import { MyPageModal } from "../../../MyPageModal";

export interface SnsShareModalProps {
  open: boolean;
  onClose: () => void;
}

export function SnsShareModal({ open, onClose }: SnsShareModalProps): React.ReactNode {
  const { data: summaryImageName } = useGetMyPageSummaryImageName();

  const handleShareToTwitter = useCallback(() => {
    const shareUrl = new URL("https://twitter.com/intent/tweet");
    shareUrl.searchParams.append(
      "text",
      "'完全栄養パン 「ベースブレッド」を試しませんか？下の紹介URLから購入すると、初回2000円OFF！※個人情報は双方に通知されません。'"
    );
    shareUrl.searchParams.append(
      "url",
      `https://shop.basefood.co.jp/share/subscriptions?key=${summaryImageName?.replace(/\.png$/g, "")}`
    );
    shareUrl.searchParams.append("hashtags", "basefood,basebread,栄養ダッシュボード");

    window.open(shareUrl.href, "_blank")?.focus();

    onClose();
  }, [onClose, summaryImageName]);

  return (
    <MyPageModal
      open={open}
      closeModal={onClose}
      footer={
        <button className="btn inline gray square text__m" onClick={onClose}>
          キャンセル
        </button>
      }
    >
      <p className="text__l text__bold text__center pd__top__m">
        ベースフードライフを
        <br />
        シェアしよう！
      </p>
      {summaryImageName && (
        <Image
          className="share__image mg__top__m"
          src={`https://asset.basefood.co.jp/share/${summaryImageName}`}
          alt="ベースフードでおいしい健康習慣"
          size={{ width: 360, height: 190 }}
        />
      )}
      <p className="text__s text__gray__dark mg__top__s">
        ※最新の情報が未反映の場合は、翌日以降に再度お試しください。
      </p>
      <div className="text__center mg__top__m flex justify-center">
        <div className="text__center text__bold text__s text__m__pc">
          <a
            href="#"
            className={clsx("flex align-center", styles.twitterContainer)}
            onClick={handleShareToTwitter}
          >
            <Image
              src="https://asset.basefood.co.jp/images/parts/x-twitter.svg"
              size={{ width: 30, height: 30 }}
              alt="twitter"
            />
            <span className={clsx("text__m text__bold text__yellow", styles.borderBottom)}>
              Xでシェア
            </span>
          </a>
        </div>
      </div>
    </MyPageModal>
  );
}
