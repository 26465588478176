/**
 * PurchaseHistoryTableのhelpers.tsと同じだけど、型が違うのでコピーして作成。
 * TODO：　型の定義を共通化したら、helpers.tsを共通化する
 */
import { Order } from "@/generated/open-api/schemas/order";
import { SnakeToCamelCaseNested } from "@/utils";

export function getPaymentStatus(order: SnakeToCamelCaseNested<Order>): string {
  if (order.cancelledAt) {
    return "支払い済み（返金済み）";
  } else if (order.paymentMethod === "daibiki") {
    return "代金引換";
  } else if (order.financialStatus === "paid") {
    return "支払い済み";
  } else {
    return "未払い";
  }
}

export function getProductPrice(
  product: SnakeToCamelCaseNested<Order["products"][0]>,
  order: SnakeToCamelCaseNested<Order>
): string | undefined {
  if (product.subscription === false) {
    return product.normalTotalPrice;
  } else if (product.isBrandNewDiscount) {
    return product.productBrandNewDiscountTotalPrice;
  } else if (order.subscriptionDiscountRate === 20) {
    return product.firstSubscriptionTotalPrice;
  } else if (order.subscriptionDiscountRate === 10) {
    return product.afterSecondSubscriptionTotalPrice;
  } else {
    return product.normalTotalPrice;
  }
}

export function isDaibiki(order: SnakeToCamelCaseNested<Order>): boolean {
  return !order.isShowCashDeliveryFee && order.paymentMethod === "daibiki";
}

export function getPaymentAmount(order: SnakeToCamelCaseNested<Order>): number {
  let amount = 0;
  if (isDaibiki(order)) {
    amount = Number(order.totalPrice);
  } else {
    amount = Number(order.totalPrice) + Number(order.totalPriceCashDeliveryFee);
  }

  return amount;
}
