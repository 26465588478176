import { useCallback, useMemo, useState } from "react";

import { keepPreviousData } from "@tanstack/react-query";
import { addHours, format, parse } from "date-fns";
import { deepEqual } from "fast-equals";
import { pick } from "ramda";

import { UsePointType } from "@/generated/open-api/schemas";
import { useValidateCoupon } from "@/generated/open-api/subscription/subscription";
import { PaymentMethod } from "@/models/payment/consts";
import { SubscriptionModel } from "@/models/subscription/type";
import { useParsedSubscriptionCart, useParsedUpdateSubscriptionCoupon } from "@/queries";
import { useParsedUpdateSubscription } from "@/queries/subscription/useParsedUpdateSubscription";
import { toLocalISOString } from "@/utils";
import { useOnce } from "@/utils/hooks";
import { objectMerge } from "@/utils/object";

import { DeliveryScheduleFormSchemaValue } from "./DeliverySchedule/DeliveryScheduleForm/schema";
import { CartProduct } from "./types";

interface GetCanChangeOrderParams {
  subscription: SubscriptionModel | undefined;
  deliveryDate: Date | undefined;
}

export const getCanChangeOrder = (params: GetCanChangeOrderParams) => {
  const { subscription, deliveryDate } = params;
  if (!subscription) return true;
  if (subscription.orderIds.length !== 1 || !subscription.isFirstSubscription) {
    // 新規、2回目以降の注文は可能
    return true;
  }
  const now = new Date();
  const firstOrderDate = new Date(subscription.orders[0].createdAt);

  if (!deliveryDate) {
    const gapHours = 24 * 4 + 9;
    // 4日経過している場合は変更可能
    return addHours(firstOrderDate, gapHours) < now;
  }

  // 配達日の24時間後以降であれば変更可能
  const nextDate = addHours(deliveryDate, 24);
  return nextDate < now;
};

type SubmitParams = {
  subscription: SubscriptionModel;
  deliveryScheduleValues: DeliveryScheduleFormSchemaValue;
};

export const useSubmit = () => {
  const { mutateAsync: updateSubscription } = useParsedUpdateSubscription();
  return useCallback(
    async (params: SubmitParams) => {
      const { subscription, deliveryScheduleValues } = params;

      return await updateSubscription({
        data: objectMerge(subscription, {
          nextOrderArrivalDate: format(deliveryScheduleValues.deliveryDate, "yyyy/MM/dd"),
          nextOrderArrivalTimezone: deliveryScheduleValues.deliveryTimezone,
          deliveryLocationCode: deliveryScheduleValues.deliveryReceiveOption,
          isFastDelivery: deliveryScheduleValues.isFastDelivery,
        }),
      });
    },
    [updateSubscription]
  );
};

export const useDeliveryScheduleValues = (subscription: SubscriptionModel | undefined) => {
  const defaultScheduleValues = useMemo<Partial<DeliveryScheduleFormSchemaValue>>(() => {
    if (!subscription) return {};
    const nextDeliveryDate = parse(subscription.nextOrderArrivalDate, "yyyy/MM/dd", new Date());
    return {
      deliveryDate: nextDeliveryDate,
      deliveryTimezone: subscription.calcNextOrderArrivalTimezone,
      deliveryReceiveOption: subscription.deliveryLocationCode,
      isFastDelivery: subscription.isFastDelivery,
    } as Partial<DeliveryScheduleFormSchemaValue>;
  }, [subscription]);

  const [scheduleValues, setScheduleValues] = useState(defaultScheduleValues);

  useOnce(() => {
    setScheduleValues(defaultScheduleValues);
  }, !!subscription);

  const isEditedSchedule = useMemo(
    () =>
      !deepEqual(
        {
          ...defaultScheduleValues,
          deliveryDate:
            defaultScheduleValues.deliveryDate &&
            toLocalISOString(defaultScheduleValues.deliveryDate).slice(0, 10),
        },
        {
          ...scheduleValues,
          deliveryDate:
            scheduleValues.deliveryDate &&
            toLocalISOString(scheduleValues.deliveryDate).slice(0, 10),
        }
      ),
    [defaultScheduleValues, scheduleValues]
  );
  return { defaultScheduleValues, scheduleValues, setScheduleValues, isEditedSchedule };
};

/**
 * クーポンのバリデーションと適用を1つにまとめたカスタムフック
 * @returns
 */
export const useApplyCoupon = () => {
  const { mutateAsync: validateCoupon } = useValidateCoupon();
  const { mutateAsync: updateCoupon } = useParsedUpdateSubscriptionCoupon();
  return useCallback(
    async (coupon: string, subscription: SubscriptionModel) => {
      const deliveryDate = parse(subscription.nextOrderArrivalDate, "yyyy/MM/dd", new Date());
      if (!subscription || !deliveryDate) return;
      await validateCoupon({
        data: {
          coupon,
          delivery_date: format(deliveryDate, "yyyy-MM-dd"),
          is_freeze: false,
          products: subscription.products.map((product) => ({
            variant_id: String(product.variantId),
            quantity: String(product.quantity),
            subscription: true,
          })),
        },
      });
      return await updateCoupon({
        data: { ...subscription, coupon },
      });
    },
    [updateCoupon, validateCoupon]
  );
};

export interface UseSubscriptionCartValuesParam {
  products: CartProduct[];
  nextOrderArrivalDate?: string;
  paymentMethod?: PaymentMethod;
  nextUsePoint?: number;
  nextUsePointType: UsePointType;
  coupon: string;
}

/**
 * カート情報の取得と初期値の管理を1つにまとめたカスタムフック
 * @returns
 */
export const useSubscriptionCartValues = (subscription: UseSubscriptionCartValuesParam) => {
  const { data: subscriptionCart } = useParsedSubscriptionCart(
    {
      products: subscription.products.map((product) => ({
        variantId: String(product.variantId),
        quantity: String(product.quantity),
        subscription: true,
      })),
      deliveryDate: subscription.nextOrderArrivalDate,
      usePoint: subscription.nextUsePoint ?? 0,
      usePointType: subscription.nextUsePointType,
      coupon: subscription.coupon || undefined,
    },
    { placeholderData: keepPreviousData }
  );

  const [defaultSubscriptionCart, setDefaultSubscriptionCart] = useState(subscriptionCart);
  useOnce(() => {
    setDefaultSubscriptionCart(subscriptionCart);
  }, !!subscriptionCart);

  const isEditedSubscriptionCart = useMemo(() => {
    if (!subscriptionCart || !defaultSubscriptionCart) return false;
    // このhooksで管理している値のみ比較
    return !deepEqual(
      pick(["usePointType", "coupon", "products"], subscriptionCart),
      pick(["usePointType", "coupon", "products"], defaultSubscriptionCart)
    );
  }, [subscriptionCart, defaultSubscriptionCart]);

  // デフォルト値を最新の値にリセットする
  const resetDefaultSubscriptionCart = useCallback(() => {
    setDefaultSubscriptionCart(subscriptionCart);
  }, [subscriptionCart]);

  return {
    subscriptionCart,
    defaultSubscriptionCart,
    isEditedSubscriptionCart,
    resetDefaultSubscriptionCart,
  };
};
