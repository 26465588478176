"use client";

import clsx from "clsx";

import { CircularProgressBar } from "@/components";
import { RANK_MAP } from "@/components/template/mypage/settings";
import { RankNameTransformed } from "@/generated/open-api/schemas";
import { useParsedGetCustomer, useParsedGetPointMile } from "@/queries";

import styles from "./MileRankInfo.module.scss";

export interface MileRankInfoProps {
  rankCircleBgColor?: string;
}

export function MileRankInfo({ rankCircleBgColor }: MileRankInfoProps): React.ReactNode {
  const { data: miles } = useParsedGetPointMile();
  const { data: customer } = useParsedGetCustomer();
  const rankName = miles?.rankName || RankNameTransformed.Bronze;
  const mile = miles?.totalMile || 0;
  const nextRankUpMile = miles?.nextRankUpMile || 0;
  const nextRankUpRate = miles?.nextRankUpRate || 0;

  return (
    <div className={styles.mileUser}>
      <h1 className="text__l text__bold">{customer.lastName}さんの現在のランク</h1>
      <dl className={clsx(styles.mileUserDefinition, styles.mileWrapper)}>
        <dt>
          <img
            src={RANK_MAP[rankName].rankImage}
            width="50"
            height="50"
            className={styles.rankImg}
            alt=""
          />
        </dt>
        <dd className={clsx(styles.mileUserDescription, "text__left")}>
          <p className={clsx("text__l", "text__bold", styles[rankName])}>
            {RANK_MAP[rankName].rankNameJp}
          </p>
          <p className="text__s">
            <span className="text__l text__bold">{mile.toLocaleString()}</span>
            マイル
          </p>
        </dd>
      </dl>
      {rankName !== "Diamond" && (
        <dl className={styles.mileUserDefinition}>
          <dt>
            <div className="mile__progress__circle">
              <CircularProgressBar
                progress={nextRankUpRate}
                bgColor={rankCircleBgColor}
                barClassName={`${rankName}ProgressBar`}
              />
            </div>
          </dt>
          <dd className={clsx(styles.mileUserDescription, "text__left")}>
            <p className="text__m text__bold">次のランクまで</p>
            <p className="text__s">
              あと<span className="text__l text__bold">{nextRankUpMile}</span>マイル
            </p>
          </dd>
        </dl>
      )}
    </div>
  );
}
