"use client";

import clsx from "clsx";
import Link from "next/link";

import { Button } from "@/components/inputs";
import { useIsAppWebView } from "@/utils";
import { useMyPagePathInfo } from "@/utils/hooks";

import styles from "./MyPageFooter.module.scss";
import { LogoutButton } from "../MyPageIndex/LogoutButton";

export function MyPageFooter(): React.ReactNode {
  const { isRootPage, parentPath, parentPathName } = useMyPagePathInfo();
  const showLogoutButton = !useIsAppWebView();

  return (
    <div className={styles.root}>
      {isRootPage && showLogoutButton && <LogoutButton />}
      {!isRootPage && (
        <Link href={parentPath}>
          <Button className={styles.backButton} variants="outlined" rounded>
            <i className={clsx("fas", "fa-angle-left", styles.buttonStartIcon)} />
            {parentPathName}に戻る
          </Button>
        </Link>
      )}
    </div>
  );
}
