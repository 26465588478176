import { useCallback, useMemo, useState } from "react";

import clsx from "clsx";
import Link from "next/link";

import { Image } from "@/components/displays";
import { useParsedGetPoints } from "@/queries";
import { numberFormat } from "@/utils";

import { isExpireDateTwoMonth } from "./helpers";
import styles from "./PointBox.module.scss";

interface PointBoxProps {}

export function PointBox({}: PointBoxProps): React.ReactNode {
  const [isOpen, setIsOpen] = useState(false);

  const { data } = useParsedGetPoints();
  const showPointExpiryDate = useMemo(
    () => !isOpen && data.expiryPoints.length > 0 && isExpireDateTwoMonth(data.expiryDate),
    [data.expiryDate, data.expiryPoints.length, isOpen]
  );

  const handleOpen = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  return (
    <div className={clsx("bg__white mg__bottom__m", styles.container)}>
      <div className={clsx(styles.point)}>
        <Image
          src="https://asset.basefood.co.jp/images/parts/point.svg"
          size={{ width: 30, height: 30 }}
          alt="point"
        />
        <div className={styles.title}>
          <p className="text__m text__bold">ポイント</p>
        </div>
        <div className={styles.toggle} onClick={handleOpen}>
          <p className="text__xl text__bold">
            {numberFormat(data.total)}
            <span className="text__l">pt</span>
            <i className={clsx("fas text__l", isOpen ? "fa-angle-up" : "fa-angle-down")}></i>
          </p>
        </div>
      </div>
      {showPointExpiryDate && (
        <div className={clsx("bg__red mg__top__m", styles.expiry)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="10"
            viewBox="0 0 20 10"
            fill="none"
            className={styles.angle}
          >
            <path
              d="M0 10L8.58579 1.41421C9.36684 0.633165 10.6332 0.633163 11.4142 1.41421L20 10H0Z"
              fill="#FF6478"
            />
          </svg>
          <p className="text__white text__s text__bold text__center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              className={styles.exclamation}
            >
              <path
                d="M0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8Z"
                fill="white"
              />
              <path
                d="M8.83162 10.0731H7.16846L6.50012 3.33325H9.50012L8.83162 10.0731Z"
                fill="#FF6478"
              />
              <path
                d="M7.99995 13.3333C8.70531 13.3333 9.27711 12.7628 9.27711 12.059C9.27711 11.3552 8.70531 10.7847 7.99995 10.7847C7.29459 10.7847 6.72278 11.3552 6.72278 12.059C6.72278 12.7628 7.29459 13.3333 7.99995 13.3333Z"
                fill="#FF6478"
              />
            </svg>
            {data.expiryDate}に失効するポイントが{numberFormat(data.expiryPoints[0].expiryPoint)}
            ptあります
          </p>
        </div>
      )}

      {isOpen && (
        <div className="pd__top__m">
          <div className={clsx("bg__gray mg__top__m mg__bottom__s", styles.expiry)}>
            <p className="text__s text__bold mg__bottom__s">ポイントの有効期限</p>
            {data.expiryPoints.length === 0 ? (
              <p className="text__s">利用可能なポイントがありません</p>
            ) : (
              <ul>
                {data.expiryPoints.map((point, index) => (
                  <li className={styles.expiryItem} key={index}>
                    <p
                      className={clsx(
                        "text__s",
                        isExpireDateTwoMonth(point.expiryDate) && "text__red"
                      )}
                    >
                      {point.expiryDate}まで
                    </p>
                    <p
                      className={clsx(
                        "text__m text__bold",
                        isExpireDateTwoMonth(point.expiryDate) && "text__red"
                      )}
                    >
                      {numberFormat(point.expiryPoint)}
                      <span className="text__s">pt</span>
                    </p>
                  </li>
                ))}
              </ul>
            )}
          </div>{" "}
        </div>
      )}

      <div className="text__right mg__top__s">
        <Link href="/invite" className={styles.inviteLink}>
          ポイントを増やすには
        </Link>
      </div>
    </div>
  );
}
