import React from "react";

import clsx from "clsx";

import { Column, Row } from "@/components/containers";
import { Image } from "@/components/displays";
import { Hr } from "@/components/displays";

import styles from "./Substitute.module.scss";

export function Substitute() {
  return (
    <Column className={styles.root}>
      <p className={clsx("text__xl", "text-center", "text__bold", "mg__top__m", "mg__bottom__m")}>
        <span className={clsx("bg__white", "text__red", styles.markpen)}>33種類</span>
        の栄養素が
        <br />
        ギュッと1食に！
      </p>
      <p className={clsx("text__m", "text-center")}>
        健康や予防を目的とした食事では
        <br />
        <span className={clsx("text__l", "text__red", "text__bold")}>「栄養バランス」</span>
        が最も大事！
      </p>
      <div className={clsx("bg__white", styles.bg, "mg__top__m", "mg__bottom__m")}>
        <p className={clsx("text__m", "text__bold", "mg__bottom__xs")}>
          BASE BREAD®に含まれる栄養素
        </p>
        <p className={clsx("text__s", "mg__bottom__m")}>1食あたりの推奨摂取量との各栄養素比較</p>
        <Image
          className={styles.image}
          aspectRatio="49 / 109"
          alt="グラフ"
          src="https://asset.basefood.co.jp/images/graph/graph_bb_plain_v3_10_v.png"
        />
        <p className={clsx("text__s", "text__gray__dark", "text__left", "mg__top__m")}>
          ※推定値
          ※栄養素等表示基準値（18才以上、基準熱量2,200kcal）に基づき、1日分の基準値の1/3を100とした場合
          ※プレーン1食2袋の場合
        </p>
      </div>
      <p className={clsx("text__m", "text__left")}>
        <span className={clsx("text__red", "text__bold")}>すべての栄養素の基準を満たすこと</span>
        が健康なからだづくりには欠かせません。ベースフードを生活に取り入れて、1日の栄養バランスを整えましょう！
      </p>
      <Hr className={styles.hr} />
      <p className={clsx("text__m", "pd__top__m", "mg__bottom__m", "text__left")}>
        ベースフードの考える「健康」についてもっと詳しく知りたい方はこちらの記事もチェック！
      </p>
      <Column className={clsx("bg__white", styles.bg, "mg__top__m", "text__left")}>
        <Row className={clsx("mg__bottom__s")}>
          <Column className={styles.articleSectionTitle}>
            <p className={clsx("text__s", "text__yellow", "text__bold", "mg__bottom__xs")}>
              ベースフードの考える「健康」
            </p>
            <p className={clsx("text__m", "text__bold")}>健康に必要な栄養ってなに？</p>
          </Column>
          <Image
            className={styles.image}
            containerClassName={styles.flagImageContainer}
            size={{ width: 34, height: 45 }}
            alt="フラグ"
            src="https://asset.basefood.co.jp/images/parts/flag_basefood.png"
          />
        </Row>
        <p className="text__s">
          ベースフードが大事にする健康についての考えを代表の橋本にインタビュー。健康な日々を送る、ヒントや指針をお伝えします。
        </p>
      </Column>

      <div className={clsx("text__center", "mg__top__m", "mg__bottom__m")}>
        <a
          className={clsx("btn", "inline", "yellow", "round", "text__m")}
          target="_blank"
          href="https://basefood.co.jp/magazine/column/interview/5793/"
        >
          記事を読む
        </a>
      </div>
    </Column>
  );
}
