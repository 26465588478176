"use client";

import { useCallback } from "react";

import { LoadingOverlay, useAuth } from "@/components";
import { useLogout } from "@/generated/open-api/customer/customer";
import { useClientCustomerCustomer } from "@/storage";

export function LogoutButton(): React.ReactNode {
  const { clearAccessToken } = useAuth();
  const { clearCustomer } = useClientCustomerCustomer();
  const { mutate, isPending } = useLogout();

  const handleLogout = useCallback(() => {
    mutate(undefined, {
      onSuccess: () => {
        clearAccessToken();
        clearCustomer();
        window.location.href = "/";
      },
    });
  }, [mutate, clearAccessToken, clearCustomer]);

  return (
    <LoadingOverlay isLoading={isPending}>
      <div className="row">
        <div className="col-12 text__center">
          <button type="button" className="btn inline round border__bk" onClick={handleLogout}>
            ログアウト
          </button>
        </div>
      </div>
    </LoadingOverlay>
  );
}
