import { PropsWithChildren } from "react";

import clsx from "clsx";

import { Modal, ModalProps } from "@/components/feedbacks";

import styles from "./MyPageModal.module.scss";

export interface MyPageModalProps
  extends PropsWithChildren<Omit<ModalProps, "displayCloseButton">> {
  fullWidthFooter?: boolean;
}

export function MyPageModal({
  footer,
  fullWidthFooter,
  className,
  ...props
}: MyPageModalProps): React.ReactNode {
  return (
    <Modal
      {...props}
      footer={
        footer && (
          <div className={clsx("pd__top__s pd__bottom__s", fullWidthFooter && styles.fullWidth)}>
            {footer}
          </div>
        )
      }
      displayCloseButton={false}
      className={clsx(styles.container, className)}
    />
  );
}
