import { CartCalcApiResponse } from "@/generated/open-api/schemas";
import {
  breadCategoryProductNames,
  cookieCategoryProductNames,
  deliPastaCategoryProductNames,
  pancakeCategoryProductNames,
  pastaCategoryProductNames,
  sauceCategoryProductNames,
  yakisobaCategoryProductNames,
} from "@/models/product/consts";
import { ProductModel } from "@/models/product/type";
import { SnakeToCamelCaseNested } from "@/utils";

import {
  newVariantIds,
  subscriptionProductCautions,
  subscriptionProductDescriptions,
} from "./consts";

export const orderDeliveryStatusColors = {
  None: "yellow",
  PartlyShipped: "green",
  Shipped: "green",
  Cancel: "red",
} as const;

export const groupProductsByCategory = <T extends { name: string }>(products: T[]) => {
  return products.reduce(
    (acc, product) => {
      if ((breadCategoryProductNames as ReadonlyArray<string>).includes(product.name)) {
        acc.bread.push(product);
      } else if ((cookieCategoryProductNames as ReadonlyArray<string>).includes(product.name)) {
        acc.cookie.push(product);
      } else if (
        (pastaCategoryProductNames as ReadonlyArray<string>).includes(product.name) ||
        (yakisobaCategoryProductNames as ReadonlyArray<string>).includes(product.name) ||
        (sauceCategoryProductNames as ReadonlyArray<string>).includes(product.name)
      ) {
        // 焼きそば、ソースセットはパスタに分類される
        acc.pasta.push(product);
      } else if ((pancakeCategoryProductNames as ReadonlyArray<string>).includes(product.name)) {
        acc.pancake.push(product);
      } else if ((deliPastaCategoryProductNames as ReadonlyArray<string>).includes(product.name)) {
        // これは冷凍のやつ
        acc.deliPasta.push(product);
      }
      return acc;
    },
    {
      bread: [] as T[],
      cookie: [] as T[],
      pasta: [] as T[],
      pancake: [] as T[],
      deliPasta: [] as T[],
    }
  );
};

const sauceSetSku = "103005";

interface ConvertSubscriptionProductParams {
  product: ProductModel;
  defaultProduct?: ProductModel;
  brandNewDiscounts: SnakeToCamelCaseNested<
    Required<CartCalcApiResponse>["cart"]
  >["productBrandNewDiscounts"];
}

export function convertSubscriptionProduct({
  product,
  defaultProduct,
  brandNewDiscounts,
}: ConvertSubscriptionProductParams) {
  return {
    ...product,
    isNew: newVariantIds.includes(product.variantId),
    description:
      subscriptionProductDescriptions.find((description) => description.sku === product.sku)
        ?.description ?? "",
    brandNewDiscount:
      brandNewDiscounts?.find(
        (productBrandNewDiscount) => productBrandNewDiscount.variantId === product.variantId
      ) ?? null,
    // ソースセットのタイトルは非表示
    showTitle: !(product.sku === sauceSetSku),
    cautions:
      subscriptionProductCautions.find((caution) => caution.sku === product.sku)?.cautions ?? [],
    isChanged: (defaultProduct?.quantity ?? 0) !== product.quantity,
  };
}

export type SubscriptionProduct = ReturnType<typeof convertSubscriptionProduct>;
