"use client";

import { useCallback } from "react";

import { useRouter } from "next/navigation";

import { htmlToast } from "@/components/feedbacks";
import { SYS_PARAMETER } from "@/configs/system";
import {
  useCheckHoldBackCoupon,
  useGetSubscriptionSuspense,
  useUpdateSubscription,
  useUpdateSubscriptionCoupon,
} from "@/generated/open-api/subscription/subscription";
import { getErrorMessages } from "@/utils/error";

export function PresentSection(): React.ReactNode {
  const router = useRouter();
  const {
    data: { subscription, freezeSubscription },
  } = useGetSubscriptionSuspense();
  const targetSubscription = subscription ?? freezeSubscription;
  const subscriptionId = targetSubscription?.id;

  const { data: checkHoldBackCoupon } = useCheckHoldBackCoupon(
    {
      subscription_id: subscriptionId?.toString() || "",
    },
    { query: { enabled: !!subscriptionId } }
  );

  const { mutateAsync: updateSubscriptionCoupon, isPending: isUpdatingSubscriptionCoupon } =
    useUpdateSubscriptionCoupon();
  const { mutateAsync: updateSubscription, isPending: isUpdatingSubscription } =
    useUpdateSubscription();

  const handleApplyCoupon = useCallback(async () => {
    try {
      const subscriptionWithNewCoupon = {
        ...targetSubscription,
        coupon: SYS_PARAMETER.unsubscribePresentCouponId,
      };

      // クーポンを適用する
      await updateSubscriptionCoupon({
        data: subscriptionWithNewCoupon,
      });

      // subscriptionの更新
      await updateSubscription({
        data: subscriptionWithNewCoupon,
      });
      htmlToast.success("クーポンを適用しました。");
    } catch (error: unknown) {
      const errorMessages = getErrorMessages(error);
      htmlToast.error(errorMessages);
    } finally {
      router.push("/mypage#order_summary_component");
    }
  }, [router, targetSubscription, updateSubscription, updateSubscriptionCoupon]);

  // クーポンが利用可能でない場合は表示しない
  if (checkHoldBackCoupon?.status !== "ok") {
    return null;
  }

  return (
    <section>
      <div className="col-12 col-m-10 col-offset-m-1">
        <p className="text__l text__bold text__center pd__top__l">
          いまなら次回ご利用可能な
          <br />
          <span className="text__red">
            <span className="text__xxl">1000円</span>
            <span className="text__xl">OFFクーポン</span>
          </span>
          <sup className="sup">※</sup>を
          <br className="clear__pc" />
          <span className="text__xl">プレゼント！</span>
        </p>
        <p className="text__m text__left text__center__pc mg__top__m">
          食事は続けることで、からだに変化が生まれてきます。
          <br className="clear__sp" />
          バランスのよい食事を1日に1食とることで、栄養のベースづくりをサポートします！
        </p>
        <p className="text__s text__left text__center__pc mg__top__m">
          ※おひとりさま１回のみ利用可能。
        </p>
        <div className="text__center mg__top__m mg__bottom__l">
          <button
            className="btn inline round yellow text__m"
            onClick={handleApplyCoupon}
            disabled={isUpdatingSubscriptionCoupon || isUpdatingSubscription}
          >
            クーポンを利用して継続する
          </button>
        </div>
      </div>
    </section>
  );
}
