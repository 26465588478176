"use client";
import { useCallback, useMemo, useState } from "react";

import { parse } from "date-fns";
import { useRouter } from "next/navigation";
import toast from "react-hot-toast";
import { scroller } from "react-scroll";

import { Row } from "@/components/containers";
import { LoadingOverlay } from "@/components/displays";
import { htmlToast } from "@/components/feedbacks";
import { buildCheckoutUrl } from "@/features";
import { UsePointType } from "@/generated/open-api/schemas";
import {
  frozenTemperatureProductsNames,
  normalTemperatureProductsNames,
} from "@/models/product/consts";
import {
  useGetParsedProductsByNames,
  useParsedGetCustomer,
  useParsedGetOrderWithDeliveryDate,
  useParsedGetSubscription,
  useParsedUpdateSubscriptionPoint,
} from "@/queries";
import { sumBy } from "@/utils/array";
import { getErrorMessages } from "@/utils/error";
import { useCount, useModalState } from "@/utils/hooks";

import { BottomActions } from "./BottomActions";
import { DeliverySchedule } from "./DeliverySchedule";
import { DeliveryScheduleFormSchemaValue } from "./DeliverySchedule/DeliveryScheduleForm/schema";
import {
  getCanChangeOrder,
  useApplyCoupon,
  useDeliveryScheduleValues,
  useSubmit,
  useSubscriptionCartValues,
} from "./helper";
import styles from "./MyPageSubscription.module.scss";
import { NextDelivery } from "./NextDelivery";
import { PaymentSummary } from "./PaymentSummary";
import { SkipModal, SkipModalEvent } from "./SkipModal";
import { SubscriptionSection } from "./SubscriptionSection";
import { CartProduct, Temperature } from "./types";

type MyPageSubscriptionProps = {
  temperature: Temperature;
};

const nextProductsSectionId = "next-products-section";

export function MyPageSubscription({ temperature }: MyPageSubscriptionProps) {
  const router = useRouter();
  const [loadingCount, startLoading, endLoading] = useCount();
  const isLoading = loadingCount > 0;
  const { data: customer } = useParsedGetCustomer();
  const { data: subscription, refetch: refetchSubscription } = useParsedGetSubscription();
  const targetSubscription =
    temperature === "normal" ? subscription.subscription : subscription.freezeSubscription;

  const productNames =
    temperature === "normal" ? normalTemperatureProductsNames : frozenTemperatureProductsNames;
  const { data: allProductsData } = useGetParsedProductsByNames({ names: [...productNames] });
  const allProducts = useMemo(
    () => allProductsData.map((product) => ({ ...product, quantity: 0 })),
    [allProductsData]
  );

  // 商品選択
  const [selectedProducts, setSelectedProducts] = useState<CartProduct[]>(
    targetSubscription?.products ?? []
  );
  const totalQuantity = useMemo(() => sumBy(selectedProducts, "quantity"), [selectedProducts]);
  const handleChangeQuantity = useCallback((product: CartProduct) => {
    setSelectedProducts((prevProducts) => {
      const index = prevProducts.findIndex((prev) => prev.variantId === product.variantId);
      if (index === -1) {
        return [...prevProducts, product];
      } else {
        return prevProducts.map((prev) => (prev.variantId === product.variantId ? product : prev));
      }
    });
  }, []);

  // ポイント状況
  const [pointUsage, setPointUsage] = useState<UsePointType>(
    targetSubscription?.nextUsePointType ?? "none"
  );
  const { mutateAsync: updatePoint } = useParsedUpdateSubscriptionPoint();
  const handleChangePointUsage = useCallback(
    async (pointUsage: UsePointType) => {
      if (!targetSubscription) return;
      try {
        startLoading();
        setPointUsage(pointUsage);
        await updatePoint({
          data: { ...targetSubscription, nextUsePointType: pointUsage },
        });
      } catch (e) {
        const errorMessages = getErrorMessages(e);
        htmlToast.error(errorMessages);
      } finally {
        endLoading();
      }
    },
    [endLoading, startLoading, targetSubscription, updatePoint]
  );

  // クーポン状況
  const [coupon, setCoupon] = useState(targetSubscription?.coupon ?? "");
  const applyCoupon = useApplyCoupon();
  const handleApplyCoupon = useCallback(
    async (coupon: string) => {
      if (!targetSubscription) return;
      try {
        startLoading();
        await applyCoupon(coupon, targetSubscription);
        setCoupon(coupon);
      } catch (e) {
        const errorMessages = getErrorMessages(e);
        htmlToast.error(errorMessages);
      } finally {
        endLoading();
      }
    },
    [applyCoupon, endLoading, startLoading, targetSubscription]
  );

  const {
    subscriptionCart,
    defaultSubscriptionCart,
    isEditedSubscriptionCart,
    resetDefaultSubscriptionCart,
  } = useSubscriptionCartValues({
    ...targetSubscription,
    products: selectedProducts,
    nextUsePointType: pointUsage,
    coupon,
  });

  const validPurchase =
    ((temperature === "normal"
      ? subscriptionCart?.validPurchase
      : subscriptionCart?.validFreezePurchase) ??
      false) ||
    totalQuantity === 0;

  const lastOrder = useMemo(() => {
    const orders = targetSubscription?.orders;
    return orders ? orders[orders.length - 1] : undefined;
  }, [targetSubscription?.orders]);

  const { data: deliveryDate } = useParsedGetOrderWithDeliveryDate(lastOrder?.id);

  const canChangeOrder = useMemo(
    () =>
      getCanChangeOrder({
        subscription: targetSubscription,
        deliveryDate: deliveryDate?.deliveryDate
          ? parse(deliveryDate?.deliveryDate, "yyyy/MM/dd", new Date())
          : undefined,
      }),
    [deliveryDate?.deliveryDate, targetSubscription]
  );

  const nextDeliveryDate = useMemo(
    () =>
      targetSubscription
        ? parse(targetSubscription.nextOrderArrivalDate, "yyyy/MM/dd", new Date())
        : undefined,
    [targetSubscription]
  );

  const { scheduleValues, defaultScheduleValues, setScheduleValues, isEditedSchedule } =
    useDeliveryScheduleValues(targetSubscription);

  const submit = useSubmit();
  const handleSubmit = useCallback(async () => {
    if (!subscriptionCart) return;
    if (!targetSubscription) {
      // 新規購入はこちら
      const url = buildCheckoutUrl(subscriptionCart.products);
      router.push(url);
      return;
    }
    try {
      startLoading();
      if (totalQuantity === 0) {
        router.push("/unsubscription");
        return;
      }
      await submit({
        subscription: {
          ...targetSubscription,
          coupon: subscriptionCart.coupon ?? "",
          nextUsePointType: subscriptionCart.usePointType ?? "none",
          products: subscriptionCart.products,
        },
        deliveryScheduleValues: scheduleValues as DeliveryScheduleFormSchemaValue,
      });
      // 初期化処理
      await refetchSubscription();
      await resetDefaultSubscriptionCart();
      toast.success("更新が完了しました");
    } finally {
      endLoading();
    }
  }, [
    targetSubscription,
    subscriptionCart,
    startLoading,
    totalQuantity,
    submit,
    scheduleValues,
    refetchSubscription,
    resetDefaultSubscriptionCart,
    router,
    endLoading,
  ]);

  const handleCancel = useCallback(() => {
    setPointUsage(defaultSubscriptionCart?.usePointType ?? "none");
    setCoupon(defaultSubscriptionCart?.coupon ?? "");
    setSelectedProducts(defaultSubscriptionCart?.products ?? []);
    setScheduleValues(defaultScheduleValues);
  }, [defaultSubscriptionCart, defaultScheduleValues, setScheduleValues]);

  const [isOpenSkip, openSkip, closeSkip] = useModalState<SkipModalEvent>();

  const handleSkip = useCallback(async () => {
    const res = await openSkip();
    if (res.type === "cancel") {
      return;
    } else if (res.type === "change-products") {
      scroller.scrollTo(nextProductsSectionId, {
        duration: 500,
        delay: 0,
        smooth: "easeInOutQuart",
      });
    } else if (res.type === "skip") {
      try {
        startLoading();
        await refetchSubscription();
        toast.success("お届け予定日を更新しました。");
      } finally {
        endLoading();
      }
    }
  }, [endLoading, openSkip, refetchSubscription, startLoading]);

  return (
    <LoadingOverlay isLoading={isLoading} className={styles.root}>
      <div className={styles.body}>
        <SubscriptionSection
          title="お届け予定日"
          subTitle={temperature === "normal" ? "常温配送" : "冷凍配送"}
          image="https://asset.basefood.co.jp/images/parts/illust_driver.png"
          imageSize={{ width: 80, height: 100 }}
          imageClassName={styles.driverImage}
          edited={isEditedSchedule}
          temperature={temperature}
        >
          <DeliverySchedule
            subscription={targetSubscription}
            onChange={setScheduleValues}
            onSkip={!isEditedSchedule ? handleSkip : undefined}
            values={scheduleValues}
            canEdit={canChangeOrder}
          />
        </SubscriptionSection>
        <LoadingOverlay isLoading={!subscriptionCart}>
          <SubscriptionSection
            id={nextProductsSectionId}
            title="BASE FOOD ボックス"
            subTitle="次回お届けの"
            image="https://asset.basefood.co.jp/images/parts/illust_box.png"
            imageSize={{ width: 91, height: 52 }}
            edited={isEditedSubscriptionCart}
            temperature={temperature}
          >
            <NextDelivery
              subscription={targetSubscription}
              nutrients={
                temperature === "normal" ? subscription.nutrients : subscription.freezeNutrients
              }
              temperature={temperature}
              cart={subscriptionCart}
              defaultCart={defaultSubscriptionCart}
              allProducts={allProducts}
              onChangeQuantity={canChangeOrder ? handleChangeQuantity : undefined}
            />
            <PaymentSummary
              onChangePointUsage={handleChangePointUsage}
              onApplyCoupon={handleApplyCoupon}
              cart={subscriptionCart}
              defaultCart={defaultSubscriptionCart}
              validPurchase={validPurchase}
            />
          </SubscriptionSection>
        </LoadingOverlay>
      </div>
      {subscriptionCart && (
        <Row className={styles.bottomActions}>
          <BottomActions
            cart={subscriptionCart}
            validPurchase={validPurchase}
            canChangeOrder={canChangeOrder}
            submitButtonTitle={targetSubscription ? "変更を保存" : "レジに進む"}
            showButtons={totalQuantity === 0 || isEditedSchedule || isEditedSubscriptionCart}
            canSubmit={
              (totalQuantity === 0 ||
                (validPurchase && (isEditedSchedule || isEditedSubscriptionCart))) &&
              !!subscriptionCart
            }
            onSubmit={handleSubmit}
            onCancel={handleCancel}
          />
        </Row>
      )}
      {targetSubscription && (
        <SkipModal
          open={isOpenSkip}
          temperature={temperature}
          onAction={closeSkip}
          originalDeliveryDate={nextDeliveryDate}
          customer={customer}
          subscription={targetSubscription}
        />
      )}
    </LoadingOverlay>
  );
}
