"use client";
import { useMemo, useState } from "react";

import clsx from "clsx";

import { Column, Row } from "@/components/containers";
import { Image } from "@/components/displays";
import { Chip } from "@/components/displays";
import { CartModel } from "@/models/cart/type";
import { NutrientModel } from "@/models/nutrient/type";
import { ProductModel } from "@/models/product/type";
import { SubscriptionModel } from "@/models/subscription/type";
import { removeEmpty } from "@/utils/array";
import { objectMerge } from "@/utils/object";

import { AdditionalProductsSections } from "./AdditionalProductsSections";
import { SubscriptionProduct, convertSubscriptionProduct } from "./helper";
import styles from "./NextDelivery.module.scss";
import { NutrientCard } from "./NutrientCard";
import { ProductTotalCounts } from "./ProductTotalCounts";
import { SubscriptionProductItem } from "./SubscriptionProductItem";
import { CartProduct, Temperature } from "../types";

interface NextDeliveryProps {
  subscription?: SubscriptionModel;
  nutrients: NutrientModel[];
  temperature: Temperature;
  cart: CartModel | undefined;
  defaultCart: CartModel | undefined;
  allProducts: ProductModel[];
  onChangeQuantity?: (product: CartProduct) => void;
}

export function NextDelivery({
  subscription,
  nutrients,
  temperature,
  cart,
  defaultCart,
  allProducts,
  onChangeQuantity,
}: NextDeliveryProps): React.ReactNode {
  const originalSelectedVariantIds = useMemo(
    () => defaultCart?.products.map((product) => product.variantId) ?? [],
    [defaultCart]
  );
  const [showMoreNutrients, setShowMoreNutrients] = useState(false);
  const [firstNutrient, ...otherNutrients] = nutrients;

  const selectedSubscriptionProducts = useMemo(
    () =>
      !cart
        ? []
        : removeEmpty(
            originalSelectedVariantIds.map((variantId) => {
              const baseProduct = allProducts.find((p) => p.variantId === variantId);
              const product = cart?.products.find((p) => p.variantId === variantId);
              const defaultProduct = defaultCart?.products.find((p) => p.variantId === variantId);
              const quantity = product?.quantity ?? 0;
              return baseProduct
                ? {
                    ...convertSubscriptionProduct({
                      product: { ...baseProduct, ...product },
                      defaultProduct,
                      brandNewDiscounts: cart.productBrandNewDiscounts,
                    }),
                    quantity,
                  }
                : null;
            })
          ),
    [allProducts, cart, defaultCart?.products, originalSelectedVariantIds]
  );

  const notSelectedSubscriptionProducts = useMemo<SubscriptionProduct[]>(() => {
    return removeEmpty(
      allProducts.map((product) => {
        if (originalSelectedVariantIds.includes(product.variantId)) return null;
        const addedProduct = cart?.products.find((p) => p.variantId === product.variantId);
        const quantity = addedProduct?.quantity ?? 0;
        return {
          ...convertSubscriptionProduct({
            product: objectMerge(product, addedProduct ?? {}),
            defaultProduct: product,
            brandNewDiscounts: cart?.productBrandNewDiscounts,
          }),
          quantity,
        };
      })
    );
  }, [allProducts, cart?.productBrandNewDiscounts, cart?.products, originalSelectedVariantIds]);
  const nextArrivalDate = useMemo(
    () =>
      subscription?.nextOrderArrivalDate ? new Date(subscription?.nextOrderArrivalDate) : null,
    [subscription]
  );

  return (
    <Column className={styles[`${temperature}Container`]}>
      <Row className={styles.header}>
        <Chip variant="outlined" color="red">
          {cart?.isFirstSubscriptionOrder
            ? "継続コース割引率：20%（初回のみ）"
            : subscription?.isPastDiscount
              ? "継続コース割引率：20%"
              : "継続コース割引率：10%"}
        </Chip>
      </Row>
      <Column className={styles.body}>
        {subscription && (
          <>
            <Row className={styles.amount}>
              <ProductTotalCounts
                temperature={temperature}
                products={selectedSubscriptionProducts}
              />
            </Row>
            {nutrients.length > 0 && (
              <Column className={styles.nextNutrient}>
                <Row className={styles.nutrientHeader}>
                  <Image
                    src="https://asset.basefood.co.jp/images/parts/txt_nutrients.png"
                    alt="食材に置き換えると"
                    size={{ width: 279, height: 44 }}
                  />
                </Row>
                <Column className={styles.nutrientItems}>
                  {firstNutrient && <NutrientCard nutrient={firstNutrient} />}
                  {!showMoreNutrients ? (
                    <Row className={styles.showMoreNutrients}>
                      <span
                        onClick={() => setShowMoreNutrients(true)}
                        className={clsx("text__bold", "text__s")}
                      >
                        さらに表示
                      </span>
                    </Row>
                  ) : (
                    <>
                      {otherNutrients.map((nutrient) => (
                        <NutrientCard key={nutrient.nutrient} nutrient={nutrient} />
                      ))}
                      <p className={clsx("text__s", "text__bold", "text__center")}>
                        その他28種類の栄養素が入っています。
                      </p>
                      <p
                        className={clsx(
                          "text__s",
                          "text__gray__dark",
                          "mg__top__s",
                          "mg__bottom__s"
                        )}
                      >
                        累計食数を「BASE BREAD®︎
                        チョコレート」1袋分の各栄養成分値を元に算出し、食材例で換算したものです。各食材の栄養成分は八訂食品栄養成分表を参照し、各食材重量は「食品の栄養とカロリー事典　第3版
                        | 女子栄養大学出版部 」を参照しています。
                      </p>
                      <Row className={styles.showMoreNutrients}>
                        <span
                          onClick={() => setShowMoreNutrients(false)}
                          className={clsx("text__bold", "text__s")}
                        >
                          閉じる
                        </span>
                      </Row>
                    </>
                  )}
                </Column>
              </Column>
            )}

            <Column className={styles.subscriptionProducts}>
              {selectedSubscriptionProducts.map((product) => (
                <SubscriptionProductItem
                  key={product.variantId}
                  product={product}
                  nextArrivalDate={nextArrivalDate}
                  isFirstSubscriptionOrder={cart?.isFirstSubscriptionOrder ?? false}
                  onChangeQuantity={
                    onChangeQuantity
                      ? (quantity) => onChangeQuantity({ variantId: product.variantId, quantity })
                      : undefined
                  }
                />
              ))}
            </Column>
          </>
        )}
        <Column className={styles.additionalProducts}>
          <AdditionalProductsSections
            products={notSelectedSubscriptionProducts}
            subscription={subscription}
            onChangeQuantity={onChangeQuantity}
            temperature={temperature}
          />
        </Column>
      </Column>
    </Column>
  );
}
