// TODO: 新商品かどうかは、APIから取得するべき
export const newVariantIds = [
  33022154375244, // こしあん
  39330352562255, // ペッパー
  39330352562256, // チーズ
  40330352562208, // 旨辛まぜそば
];

// TODO: 商品説明は、APIから取得するべき
export const subscriptionProductDescriptions = [
  {
    sku: "102012",
    description: "朝食にもランチにも、やさしい甘みでアレンジ楽しい全粒粉ミニ食パン。",
  },
  {
    sku: "102013",
    description: "みずみずしいレーズンの甘みがじゅわっと広がる、サクもち食感のミニ食パン。",
  },
  {
    sku: "102007",
    description: "全粒粉のやさしい甘み、やわもち食感の丸パン。ハンバーガーなどアレンジ自在。",
  },
  {
    sku: "102014",
    description: "ふっくらとした生地にまろやかな甘み広がる、芳醇なあじわいのロールパン。",
  },
  {
    sku: "102016",
    description: "北海道産小豆の上品な甘み広がるしっとり生地のあんぱん。",
  },
  {
    sku: "102008",
    description: "しっとりチョコが折り込まれた、やわもち食感のマーブルチョコパン。",
  },
  {
    sku: "102009",
    description: "まろやかな甘みにほっとするメープルパン。やわもち食感のスティックタイプ。",
  },
  {
    sku: "102010",
    description: "本格シナモンが豊かに香るシナモンパン。やわもち食感のスティックタイプ。",
  },
  {
    sku: "102011",
    description: "やわもち生地に濃厚カレーがたっぷり入った、満足感のある惣菜パン。",
  },
  {
    sku: "101002",
    description: "クリーム系など濃厚ソースにぴったり、もちもち食感の平打ち麺。ゆで時間2分。",
  },
  {
    sku: "101001",
    description: "ペペロンチーノから焼きそばまで、アレンジ自在な細麺。ゆで時間1分。",
  },
  {
    sku: "103005",
    description: "2種類の味がたのしめる4食セット（ボロネーゼ2食+まぜそば2食）をご用意しています。",
  },
  {
    sku: "104003",
    description: "ココアの風味とサクサク食感のバランスが良い、落ち着く大人のクッキー。",
  },
  {
    sku: "104004",
    description: "アールグレイの香り引き立つ、気分ほぐれるクッキー。甘さひかえめ。",
  },
  {
    sku: "104005",
    description:
      "上質な抹茶にコクのある黒糖をあわせた、深みある大人の味わい。サクサク食感のクッキー。",
  },
  {
    sku: "104006",
    description:
      "粗挽きココナッツの風味と香りがひとくちごとに広がる、ほんのり甘いクッキー。サクサク食感。",
  },
  {
    sku: "104007",
    description:
      "紫さつまいもを使用、子どもから大人までたのしめる自然でやさしい甘み。サクサク食感のクッキー。",
  },
  {
    sku: "104008",
    description: "お酒のお供に！ザクザク食感が特徴のピリッと香るブラックペッパー。",
  },
  {
    sku: "104009",
    description: "おやつにサクッと！ザクザク食感が特徴のやさしく香ばしいチェダーチーズ。",
  },
  {
    sku: "105001",
    description: "33種の栄養素入り。ふんわりしっとり感が楽しめるパンケーキミックス。",
  },
  {
    sku: "111001",
    description:
      "香味野菜のうまみと、ゴロゴロお肉が麺にからみあう、濃厚贅沢パスタ。たんぱく質36.7g入り。",
  },
  {
    sku: "111002",
    description:
      "「“焼き”たらこ」のプチプチした食感と「“生”たらこ」の濃厚な味わいを一度に楽しむことのできる、たっぷりたらこの旨みがギュッとつまったパスタ。たんぱく質27g入り。",
  },
  {
    sku: "111003",
    description:
      "3種のきのこの豊かな風味と濃厚なホワイトソースがもちもち麺に絡み合うクリームパスタ。たんぱく質27g入り。",
  },
  {
    sku: "111004",
    description:
      "香ばしいソースがどこか懐かしく飽きのこないソース焼きそば。カロリー控えめでやさしい味わい。たんぱく質27g入り。",
  },
  {
    sku: "111005",
    description: "花椒や唐辛子など4種のスパイスの辛味と旨味が広がる旨辛まぜそば。",
  },
];

export const subscriptionProductCautions = [
  {
    sku: "102008",
    cautions: [
      "※「BASE BREAD︎ チョコレート」は順次リニューアルを行っております。切り替え期間中、新旧品が混在する場合がございますことをご了承ください。",
    ],
  },
  {
    sku: "102009",
    cautions: [
      "※「BASE BREAD メープル」は順次リニューアルを行っております。切り替え期間中、新旧品が混在する場合がございますことをご了承ください。",
    ],
  },
  {
    sku: "102010",
    cautions: [
      "※「BASE BREAD シナモン」は順次リニューアルを行っております。切り替え期間中、新旧品が混在する場合がございますことをご了承ください。",
    ],
  },
  {
    sku: "102007",
    cautions: [
      "※「BASE BREAD︎ プレーン」は順次リニューアルを行っております。切り替え期間中、新旧品が混在する場合がございますことをご了承ください。",
    ],
  },
];
