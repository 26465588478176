"use client";

import clsx from "clsx";
import Link from "next/link";

import { Button } from "@/components/inputs";
import { Breadcrumb } from "@/components/layouts";
import { useMyPagePathInfo } from "@/utils/hooks";

import styles from "./MyPageHeader.module.scss";

export function MyPageHeader(): React.ReactNode {
  const { isRootPage, parentPath, parentPathName, currentPathName, breadcrumbItems } =
    useMyPagePathInfo();

  return (
    <div className={clsx(styles.root)}>
      <Breadcrumb current={currentPathName} items={breadcrumbItems} />
      <div className={clsx(styles.titleContainer, isRootPage && styles.isRootPage)}>
        <Link href={parentPath}>
          <Button className={styles.backButton} variants="outlined" size="sm" rounded>
            <i className={clsx("fas", "fa-angle-left", styles.buttonStartIcon)} />
            {parentPathName}に戻る
          </Button>
        </Link>
        <h3 className={clsx("mg__top__off__pc", styles.title)}>{currentPathName}</h3>
      </div>
    </div>
  );
}
