import React from "react";

import clsx from "clsx";

import { Column } from "@/components/containers";
import { Image } from "@/components/displays";

import styles from "./HighPrice.module.scss";

export function HighPrice() {
  return (
    <Column className={styles.root}>
      <p
        className={clsx(
          "text__xl",
          "text__bold",
          "text-center",
          "mg__top__m",
          "mg__bottom__m",
          styles.subtitle
        )}
      >
        コンビニより
        <span className={clsx("bg__white", "text__red", styles.markpen)}>お得</span>
        <br />
        しかも<span className={clsx("bg__white", "text__red", styles.markpen)}>栄養満点</span>
      </p>
      <Image
        alt="コンビニ比較"
        className={styles.image}
        containerClassName={styles.compareImageContainer}
        aspectRatio="596 / 431"
        src="https://asset.basefood.co.jp/images/ph/ph_skip_reason_comparison_chocolate_v1_12.png"
      />
      <div className={styles.graphImageContainer}>
        <Image
          alt="栄養素グラフ"
          className={styles.image}
          src="https://asset.basefood.co.jp/images/ph/graph_skip_reason_chocolate_v1_12.png"
        />
        <p className={clsx("text__s", "text__gray__dark", "text__left", "mg__top__m")}>
          ※栄養素等表示基準値（18才以上、基準熱量2,200kcal）に基づき、1日分の基準値の1/3を100とした場合。
        </p>
      </div>
      <p className={clsx("text__m", "text__left")}>
        栄養バランスを考えながらの献立決定はお金も時間もかかります。
        <br />
        BASE FOOD®︎を毎日食べることで、お得に健康習慣を身につけませんか？
      </p>
      <p className={clsx("text__s", "text__gray__dark", "text__left", "mg__top__m")}>
        ※1 サラダ、サンドイッチ、野菜ジュース、ヨーグルトの場合。※2 BASE BREAD®︎
        チョコレートの場合。継続コース2回目以降10%OFF価格。
      </p>
    </Column>
  );
}
