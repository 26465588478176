"use client";
import { useCallback } from "react";

import clsx from "clsx";
import { useRouter, useSearchParams } from "next/navigation";

import { buildCheckoutUrl } from "@/features";
import { ProductModel, ProductTemperature } from "@/models/product/type";
import { setClientFrontStoreCartItems } from "@/storage";

import styles from "./DeliveryAddition.module.scss";

interface DeliveryAdditionProps {
  temperature: ProductTemperature;
  products: ProductModel[];
  showComeback?: boolean;
  isFreezeDeliveryArea?: boolean;
}

export function DeliveryAddition({
  temperature,
  showComeback,
  products,
  isFreezeDeliveryArea = true,
}: DeliveryAdditionProps): React.ReactNode {
  const params = useSearchParams();
  const router = useRouter();

  // サブスク再開を再現した関数。サブスクの情報をカートに保存したうえでcheckoutページに飛ぶ
  const moveComebackCheckout = useCallback(() => {
    const newCartItems = products.map((product) => {
      return {
        quantity: product.quantity,
        variantId: product.variantId,
        subscription: true,
      };
    });
    setClientFrontStoreCartItems(newCartItems);

    if (params.get("patterna") === "true") {
      if (temperature === "normal") {
        window.location.href = "/mypage/subscription";
        return;
      } else {
        window.location.href = "/mypage/freeze_subscription";
      }
    }

    router.push(buildCheckoutUrl(newCartItems));
  }, [params, products, router, temperature]);

  return (
    <div className={styles.container}>
      {showComeback && (
        <button
          className={clsx("bg__red text__s text__bold", styles.restart)}
          onClick={() => moveComebackCheckout()}
        >
          前回の注文内容で再開する
          <i className={clsx("fas fa-angle-right", styles.restartAngle)} />
        </button>
      )}
      <div>
        <a
          href={temperature === "normal" ? "/mypage/subscription" : "/mypage/freeze_subscription"}
          className={clsx(
            "text__m text__bold mg__bottom__s",
            styles.subscriptionAdd,
            styles.freeze
          )}
        >
          <span
            className={clsx(
              "tag inline text__white mini square",
              styles.subscriptionTag,
              temperature === "normal" ? "yellow" : "darkblue",
              !isFreezeDeliveryArea && styles.disabled
            )}
          >
            {temperature === "normal" ? "常温" : "冷凍"}配送
          </span>
          新しく追加する
          <i className={clsx("fas fa-angle-right", styles.angle)}></i>
          {!isFreezeDeliveryArea && (
            <p className="text__s text__gray__dark text__right mg__top__xs">
              冷凍配送対象外エリアのため登録できません。
            </p>
          )}
        </a>
      </div>
    </div>
  );
}
