"use client";

import { useCallback, useState } from "react";

import clsx from "clsx";
import Link from "next/link";

import { Image, ProgressBar } from "@/components/displays";
import { MileModel } from "@/models/mile/type";
import { SubscriptionResponseModel } from "@/models/subscription/type";
import { numberFormat } from "@/utils";

import { NutrientPanel } from "./NutrientPanel";
import { SnsShareModal } from "./SnsShareModal";
import styles from "./StatusBox.module.scss";
import { RANK_MAP } from "../../settings";
import "@/style/rankColor.scss";

interface StatusBoxProps {
  mile: MileModel;
  subscription: SubscriptionResponseModel;
}

export function StatusBox({ mile, subscription }: StatusBoxProps): React.ReactNode {
  const [openSnsShare, setOpenSnsShare] = useState(false);

  const {
    calculatedNutrients,
    continuousMonths,
    continuousWeeks,
    nextRankUpMile,
    nextRankUpRate,
    rankName,
    totalMeals,
    totalMile,
  } = mile;
  const rank = RANK_MAP[rankName];

  const toggleSnsShare = useCallback(() => {
    setOpenSnsShare((prev) => !prev);
  }, []);

  return (
    <div className={clsx(styles.container, rankName)}>
      <div className={styles.rankContainer}>
        <div className={styles.flex}>
          <dl className={styles.rank}>
            <dt className={clsx("text__center", styles.image)}>
              <Image src={rank.rankImage} size={{ width: 70, height: 70 }} alt="rank" />
              <p className={clsx("text__s text__bold", `${rankName}Font`)}>{rank.rankNameJp}</p>
            </dt>

            <dd className={clsx(styles.rankName)}>
              <p
                className={clsx(
                  "text__xxl text__bold",
                  `${rankName}Font`,
                  rankName !== "Diamond" && "text__right",
                  rankName === "Diamond" && "mg__top__m"
                )}
              >
                {` ${numberFormat(totalMile)} `}
                <span className="text__m">マイル</span>
              </p>
              {rankName !== "Diamond" && (
                <>
                  <ProgressBar
                    num={nextRankUpRate}
                    max={100}
                    containerClassName={styles.progressBarContainer}
                    className={`${rankName}ProgressBar`}
                  />
                  <p className={clsx("text__s text__right mg__top__s", `${rankName}Font`)}>
                    次のランクまであと
                    <span className="text__bold">{numberFormat(nextRankUpMile)}</span>
                    マイル
                  </p>
                </>
              )}
            </dd>
          </dl>
        </div>

        <Link
          href="/mileprogram"
          className={clsx(
            "bg__white text__s text__bold mg__top__m",
            styles.rankDetail,
            `${rankName}Font`,
            rankName === "Diamond" && styles.rankDiamond
          )}
        >
          {rankName === "Diamond" ? "ランクと特典をみる" : "ランクと特典、プレゼントをみる"}
          <i className={clsx("fas fa-angle-right", styles.present)}></i>
        </Link>

        {calculatedNutrients && (
          <NutrientPanel
            calculatedNutrients={calculatedNutrients}
            continuousMonths={continuousMonths}
            continuousWeeks={continuousWeeks}
            totalMeals={totalMeals}
            subscription={subscription}
            rankName={rankName}
          />
        )}

        <div className="text__right mg__top__m">
          <button type="button" className="btn inline yellow mini" onClick={toggleSnsShare}>
            SNSでシェアする
          </button>
          <SnsShareModal open={openSnsShare} onClose={toggleSnsShare} />
        </div>
      </div>
    </div>
  );
}
