"use client";
import { useState } from "react";

import clsx from "clsx";

import { Column, Row } from "@/components/containers";
import { Button, Input, RadioButtonGroup } from "@/components/inputs";
import { PRODUCT_SALE_PARAMETER } from "@/configs/system";
import { UsePointType } from "@/generated/open-api/schemas";
import { CartModel } from "@/models/cart/type";
import { useParsedGetPoints } from "@/queries";
import { numberFormat } from "@/utils";
import { formatCurrency } from "@/utils/currency";

import { useChangedFields } from "./helpers";
import styles from "./PaymentSummary.module.scss";

interface PaymentSummaryProps {
  onChangePointUsage: (pointUsage: UsePointType) => void;
  onApplyCoupon: (coupon: string) => void;
  cart: CartModel | undefined;
  defaultCart: CartModel | undefined;
  validPurchase: boolean;
}

const pointOptions = [
  {
    value: "all",
    label: "すべてのポイントを利用する",
  },
  {
    value: "none",
    label: "ポイントを利用しない",
  },
] as const;

export function PaymentSummary({
  onChangePointUsage,
  cart,
  defaultCart,
  validPurchase,
  onApplyCoupon,
}: PaymentSummaryProps): React.ReactNode {
  const { data: pointData } = useParsedGetPoints();
  const appliedCoupon = cart?.coupon ?? "";
  const [coupon, setCoupon] = useState(appliedCoupon);
  const changedFields = useChangedFields(cart, defaultCart);

  return (
    <Column className={styles.root}>
      <Column className={styles.couponSection}>
        <p className="text__m text__bold mg__top__m mg__bottom__s">クーポンコード</p>
        <Row>
          <Input
            onChange={(e) => setCoupon(e.target.value)}
            className={styles.couponInput}
            value={coupon}
            placeholder="クーポンコード"
          />
          <Button
            variants="yellow"
            disabled={coupon === appliedCoupon}
            onClick={() => onApplyCoupon(coupon)}
          >
            適用
          </Button>
        </Row>
        {changedFields.coupon && (
          <p className="text__s text__red mg__top__s mg__bottom__xs">
            変更を保存するまでは確定されていません
          </p>
        )}
      </Column>
      <Column className={styles.pointSection}>
        <p className={clsx("text__m", "text__bold")}>
          保有ポイント：<span className={clsx("text__xl", "text__red")}>{pointData.total} </span>pt
        </p>
        <Column className="mg__top__m">
          <RadioButtonGroup
            name="point-usage"
            value={cart?.usePointType}
            onChange={onChangePointUsage}
            options={pointOptions}
          />
        </Column>
        {changedFields.point && (
          <p className="text__s text__red mg__top__s mg__bottom__xs">
            変更を保存するまでは確定されていません
          </p>
        )}
      </Column>
      <Column className={styles.subTotalSection}>
        <Row className={styles.subTotalItem}>
          <span>小計：</span>
          <span
            className={clsx("text__l", "text__bold", changedFields.lineTotalPrice && "text__red")}
          >
            {formatCurrency(cart?.lineTotalPrice ?? 0)}
          </span>
        </Row>
        <Row className={styles.subTotalItem}>
          <span>配送料：</span>
          <span className={clsx("text__l", "text__bold")}>
            {formatCurrency(cart?.totalShippingFee ?? 0)}
            **
          </span>
        </Row>
        {cart?.lineTotalPrice !== 0 && (
          <>
            <Row className={styles.subTotalItem}>
              <span>代引き手数料：</span>
              <span className={clsx("text__l", "text__bold")}>
                {formatCurrency(cart?.totalPriceCashDeliveryFee ?? 0)}
                **
              </span>
            </Row>
            {(cart?.couponDiscount ?? 0) > 0 && (
              <Row className={styles.subTotalItem}>
                <span>クーポン値引き：</span>
                <span className={clsx("text__l", "text__bold", "text__red")}>
                  - {formatCurrency(cart?.couponDiscount ?? 0)}
                </span>
              </Row>
            )}
            {(cart?.pointDiscount ?? 0) > 0 && (
              <Row className={styles.subTotalItem}>
                <span>ポイント値引き：</span>
                <span className={clsx("text__l", "text__bold", "text__red")}>
                  - {formatCurrency(cart?.pointDiscount ?? 0)}
                </span>
              </Row>
            )}
          </>
        )}
      </Column>
      <Column className={styles.totalSection}>
        {!validPurchase && (
          <div className={clsx("mg__bottom__m", "text__center", styles.invalidPriceAlert)}>
            <p className="text__m text__white">
              商品金額{numberFormat(PRODUCT_SALE_PARAMETER.minDeliveryAmountNormal)}円以上から
              <br />
              ご購入いただけます。
            </p>
          </div>
        )}
        <Row className={styles.subTotalItem}>
          <span>合計：</span>
          <span
            className={clsx("text__xl", "text__bold", changedFields.lineTotalPrice && "text__red")}
          >
            {formatCurrency(
              cart?.lineTotalPrice !== 0
                ? (cart?.totalPrice ?? 0) + (cart?.totalPriceCashDeliveryFee ?? 0)
                : 0
            )}
          </span>
        </Row>
        <p className="text__s text__gray__dark text-right">
          ※価格はすべて税込表示 *税率8% **税率10%
        </p>
      </Column>
    </Column>
  );
}
