import { OrderModel } from "@/models/order/type";
import { formatCurrency } from "@/utils/currency";

export function getPaymentStatus(order: OrderModel): string {
  if (order.cancelledAt) {
    return "支払い済み（返金済み）";
  } else if (order.paymentMethod === "daibiki") {
    return "代金引換";
  } else if (order.financialStatus === "paid") {
    return "支払い済み";
  } else {
    return "未払い";
  }
}

export function getDeliveryStatus(order: OrderModel): string {
  switch (order.fulfillmentStatus) {
    case "fulfilled":
      return "発送済み";
    case "partly_fulfilled":
      return "一部発送済み";
    case "cancelled":
      return "キャンセル済み";
    default:
      return "未発送";
  }
}

export function isDaibiki(order: OrderModel): boolean {
  return !order.isShowCashDeliveryFee && order.paymentMethod === "daibiki";
}

export function getPaymentAmount(order: OrderModel): string {
  let amount = 0;
  if (isDaibiki(order)) {
    amount = Number(order.totalPrice);
  } else {
    amount = Number(order.totalPrice) + Number(order.totalPriceCashDeliveryFee);
  }

  return formatCurrency(amount);
}
