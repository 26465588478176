import clsx from "clsx";

import { Image } from "@/components";

import styles from "./ImageLinkItem.module.scss";

export interface ImageLink {
  url: string;
  alt: string;
  href?: string;
  onClick?: () => void;
}

export function ImageLinkItem({ url, alt, href, onClick }: ImageLink): React.ReactNode {
  const isBlank = href && href.startsWith("http");

  return (
    <li className={styles.listItem}>
      {onClick ? (
        <button className={clsx(styles.link, styles.button)} onClick={onClick}>
          <Image
            src={url}
            containerClassName={styles.imageContainer}
            className={styles.image}
            alt={alt}
          />
        </button>
      ) : (
        <a href={href} className={styles.link} target={isBlank ? "_blank" : "_self"}>
          <Image
            src={url}
            containerClassName={styles.imageContainer}
            className={styles.image}
            alt={alt}
          />
        </a>
      )}
    </li>
  );
}
