import { useCallback, useState } from "react";

import clsx from "clsx";

import { Image } from "@/components/displays";
import {
  formatContinuousMonth,
  getContinuousMessage,
  getIngredientImageUrl,
} from "@/components/template/mypage/MyPageIndex/StatusBox/NutrientPanel/helper";
import { RankNameTransformed } from "@/generated/open-api/schemas";
import { CalculatedNutrientModel } from "@/models/mile/type";
import { SubscriptionResponseModel } from "@/models/subscription/type";
import { numberFormat } from "@/utils";

import styles from "./NutrientPanel.module.scss";
import { NutrientTooltipIcon } from "./NutrientTooltipIcon";
import "@/style/rankColor.scss";

interface NutrientPanelProps {
  calculatedNutrients: CalculatedNutrientModel;
  continuousMonths: number;
  continuousWeeks: number;
  totalMeals: number;
  subscription: SubscriptionResponseModel;
  rankName: RankNameTransformed;
}

export function NutrientPanel({
  calculatedNutrients,
  continuousMonths,
  continuousWeeks,
  totalMeals,
  subscription,
  rankName,
}: NutrientPanelProps): React.ReactNode {
  const nutrientName = calculatedNutrients.nutrient;
  const ingredientName = calculatedNutrients.convertedIngredient;
  const ingredientNumWithUnit = calculatedNutrients.ingredientNumWithUnit;
  const unitAnnotation = calculatedNutrients.unitAnnotation;
  const continuousMonth = formatContinuousMonth(continuousMonths);
  const continuousMessage = getContinuousMessage(continuousMonths);

  const [panelNumber, setPanelNumber] = useState(1);
  const changePanel = useCallback((num: number) => () => setPanelNumber(num), []);

  return (
    <div>
      {((totalMeals > 0 && subscription.subscription) || subscription.freezeSubscription) && (
        <div className={clsx("mg__top__m mg__bottom__m", styles.panelTable)}>
          <div className={styles.panelCell}>
            <div
              className={clsx("bg__white", styles.panel, panelNumber === 1 && `${rankName}PanelOn`)}
              onClick={changePanel(1)}
            >
              <p className={clsx("text__s text__bold", `${rankName}Font`)}>累計食数</p>
              <p className={clsx("text__m text__bold wsnr", `${rankName}Font`)}>
                <span className={clsx("text__xxl", styles.largeNumber)}>
                  {numberFormat(totalMeals)}
                </span>
                食
              </p>
            </div>
          </div>

          <div className={styles.panelCell}>
            <div
              className={clsx("bg__white", styles.panel, panelNumber === 2 && `${rankName}PanelOn`)}
              onClick={changePanel(2)}
            >
              <p className={clsx("text__s text__bold", `${rankName}Font`)}>継続期間</p>
              <p className={clsx("text__m text__bold wsnr", `${rankName}Font`)}>
                <span className={clsx("text__xxl", styles.largeNumber)}>
                  {numberFormat(continuousWeeks)}
                </span>
                週間
              </p>
            </div>
          </div>
        </div>
      )}

      {totalMeals > 0 && panelNumber === 1 && (
        <dl
          className={clsx(
            "mg__bottom__m",
            styles.panelDetail,
            styles.detailLeft,
            `${rankName}Background`
          )}
        >
          <dt className={clsx("bg__white", styles.panelIcon)}>
            <img
              src={getIngredientImageUrl(calculatedNutrients.convertedIngredientEn)}
              alt="食材イメージ"
            />
          </dt>
          <dd className={clsx("text__bold", `${rankName}InnerFont`)}>
            {ingredientName} {ingredientNumWithUnit}分<sup className="sup"></sup>の{nutrientName}
            を摂取しました！
            {unitAnnotation && (
              <p className={clsx("text__s text__normal mg__top__xs", `${rankName}InnerFont`)}>
                ※{unitAnnotation}
              </p>
            )}
          </dd>
          <dd className={styles.panelQuestion}>
            <NutrientTooltipIcon rankName={rankName} />
          </dd>
        </dl>
      )}
      {totalMeals > 0 && panelNumber === 2 && (
        <dl
          className={clsx(
            "mg__bottom__m",
            styles.panelDetail,
            styles.detailRight,
            `${rankName}Background`
          )}
        >
          <dd className="text__bold">
            <p className={clsx("text__m mg__bottom__s", `${rankName}InnerFont`)}>
              継続{continuousMonth}
            </p>
            <p
              className={clsx("text__s", `${rankName}InnerFont`)}
              dangerouslySetInnerHTML={{ __html: continuousMessage }}
            />
          </dd>
          <dt className={styles.panelCongrats}>
            <Image
              src="https://asset.basefood.co.jp/images/parts/illust_woman_smile.png"
              alt="スマイル"
              size={{ width: 89, height: 80 }}
            />
          </dt>
        </dl>
      )}
    </div>
  );
}
