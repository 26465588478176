"use client";
import { useMemo } from "react";

import { Column } from "@/components/containers";
import { SubscriptionModel } from "@/models/subscription/type";

import { AdditionalProductsSection } from "./AdditionalProductsSection";
import { CartProduct, Temperature } from "../../types";
import { SubscriptionProduct, groupProductsByCategory } from "../helper";

interface AdditionalProductsSectionsProps {
  subscription: SubscriptionModel | undefined;
  products: SubscriptionProduct[];
  onChangeQuantity?: (product: CartProduct) => void;
  temperature: Temperature;
}

const additionalProductsCategories = [
  {
    title: (
      <>
        BASE BREAD<sup className="sup">®</sup>
      </>
    ),
    category: "bread",
    temperature: "normal",
  },
  {
    title: <>BASE Pancake Mix™︎</>,
    category: "pancake",
    temperature: "normal",
  },
  {
    // こっちは冷凍じゃない
    title: (
      <>
        BASE PASTA<sup className="sup">®</sup>
      </>
    ),
    category: "pasta",
    temperature: "normal",
  },
  {
    title: (
      <>
        BASE Cookies<sup className="sup">®</sup>
      </>
    ),
    category: "cookie",
    temperature: "normal",
  },
  {
    // こっちは冷凍
    title: (
      <>
        BASE PASTA<sup className="sup">®</sup>
      </>
    ),
    category: "deliPasta",
    temperature: "frozen",
  },
] as const;

export function AdditionalProductsSections({
  products,
  subscription,
  onChangeQuantity,
  temperature,
}: AdditionalProductsSectionsProps): React.ReactNode {
  const groupByProducts = useMemo(() => groupProductsByCategory(products), [products]);
  const nextArrivalDate = useMemo(
    () => (subscription ? new Date(subscription.nextOrderArrivalDate) : null),
    [subscription]
  );

  const additionalCategories = useMemo(
    () => additionalProductsCategories.filter((item) => item.temperature === temperature),
    [temperature]
  );

  return (
    <Column>
      {additionalCategories.map((item) => {
        const products = groupByProducts[item.category];
        if (products.length === 0) return null;
        const isNew = products.some((product) => product.isNew);
        return (
          <AdditionalProductsSection
            key={item.category}
            title={item.title}
            products={products}
            nextArrivalDate={nextArrivalDate}
            isFirstSubscriptionOrder={subscription?.isFirstSubscription ?? true}
            isNew={isNew}
            onChangeQuantity={onChangeQuantity}
          />
        );
      })}
    </Column>
  );
}
